import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Image as RImage } from 'react-bootstrap'
import firebase from 'firebase/app'
import { format, getDay, addMonths, endOfMonth, parse } from 'date-fns'
import { it } from 'date-fns/locale'



////////////////////////////////////////////////////////////////////////////////////////////////////////
export const isEditable = (status) => {

	let editable = true
	if (status && (status === 'testing' || status === 'operational' || status === 'extension' || status === 'expired')) {
		editable = false
	}
	return editable

}
////////////////////////////////////////////////////////////////////////////////////////////////////////
export const getTimeValue = (t) => {
	let val = t
	try {
		val = format(t, 'HH:mm', { locale: it })
	} catch (e) {
		//			let newt = firebase.firestore.Timestamp.toDate(t),
		try {

			let newt = t.toDate()
			val = format(newt, 'HH:mm', { locale: it })
		} catch (err) {
			console.log("ERROR: getTimeValue", err, t)
			try {
				let newtc = new Date(Date.UTC(1970, 0, 1))
				newtc.setUTCSeconds(t.seconds)
				let newtb = new Date(newtc)
				val = format(newtb, 'HH:mm', { locale: it })
			} catch (errb) {
				console.log("ERROR: getTimeValue", errb, t)
				val = ''

			}
		}
	}
	return val
}

////////////////////////////////////////////////////////////////////////////////////////////////////////
function dateRangeOverlaps(a_start, a_end, a_we, b_start, b_end, b_we) {
	if (a_we && b_we) {

		if (a_start < b_start && b_start < a_end) return true; // b starts in a
		if (a_start < b_end && b_end < a_end) return true; // b ends in a
		if (b_start < a_start && a_end < b_end) return true; // a in b
	} else if (!a_we && !b_we) {
		return true
	} else {
		if (a_we) {
			if (a_start < b_start && b_start < a_end) return true; // b starts in a
			if (b_start < a_start) return true; // a in b
		}
		if (b_we) {
			if (a_start < b_start) return true; // b starts in a
		}
	}
	return false;
}

////////////////////////////////////////////////////////////////////////////////////////////////////////
export function dateInRange(range_list, date) {
	for (const r in range_list) {
		const one_r = range_list[r]
		if (myTimestampToDate(one_r.startDate) <= date && myTimestampToDate(one_r.endDate) >= date) {
			return true
		}
	}
	return false;

}

////////////////////////////////////////////////////////////////////////////////////////////////////////
export function checkDatePresence(list, date) {
	let ispresent = false
	if (list && list.length > 0) {
		for (let c = 0; c < list.length; c++) {
			//		console.log(el[param], list[c][param])
			if (date === myTimestampToDate(list[c].startDate)) {
				//console.log("c", el[param], c)
				return true
			}
		}

	}
	return ispresent
}

////////////////////////////////////////////////////////////////////////////////////////////////////////
export function multipleDateRangeOverlaps(timeEntries, param) {
	let i = 0, j = 0;
	let timeIntervals = timeEntries.filter(entry => entry.startDate != null && entry.endDate != null).filter(e => e.param === param);
	if (timeIntervals != null && timeIntervals.length > 1)
		for (i = 0; i < timeIntervals.length - 1; i += 1) {
			for (j = i + 1; j < timeIntervals.length; j += 1) {
				if (
					dateRangeOverlaps(
						myTimestampToDate(timeIntervals[i].startDate).getTime(), myTimestampToDate(timeIntervals[i].endDate).getTime(), timeIntervals[i].with_end,
						myTimestampToDate(timeIntervals[j].startDate).getTime(), myTimestampToDate(timeIntervals[j].endDate).getTime(), timeIntervals[j].with_end,
					)
				) return true;
			}
		}
	return false;
}




////////////////////////////////////////////////////////////////////////////////////////////////////////
export const makeid = (length) => {
	var result = '';
	var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	var charactersLength = characters.length;
	for (var i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
}

////////////////////////////////////////////////////////////////////////////////////////////////////////
export const getSelectedElement = (id, list, comparevalue) => {
	if (!comparevalue) {
		comparevalue = 'Id'
	}
	try {

		for (let e = 0; e < list.length; e++) {
			if (list[e][comparevalue] === id) {
				return list[e]
			}
		}
	} catch (err) {
		//console.log(err) 
	}
	return {}
}

////////////////////////////////////////////////////////////////////////////////////////////////////////
export const getSelectedElementB = (id, list, comparevalue, compb) => {
	try {
		for (let e = 0; e < list.length; e++) {
			if (list[e][comparevalue] && list[e][comparevalue][compb] === id) {
				return list[e]
			}
		}
	} catch (err) { }
	return {}
}

////////////////////////////////////////////////////////////////////////////////////////////////////////
/**
 * return element index in list (using a parameter)
 * @param {element} el 
 * @param {element_list} lt list where to search
 * @param {compare_value} param param used for check
 */
export const checkComponentPresence = (el, lt, param, paramb) => {
	//	console.log(el, list, param)
	if (!param) {
		param = 'Id'
	}
	if (!paramb) {
		paramb = param
	}
	const _list = lt || []

	for (let c = 0; c < _list.length; c++) {
		//		console.log(el[param], list[c][param])
		if (el[param] === _list[c][paramb]) {
			//console.log("c", el[param], c)
			return c
		}
	}
	return -1
}


////////////////////////////////////////////////////////////////////////////////////////////////////////
export const setMyDate = (e) => {
	try {
		return firebase.firestore.Timestamp.fromDate(e.toDate())
		
	} catch (err) {
		try{
			return firebase.firestore.Timestamp.fromDate(e)
		}catch(err){
			
		}
		console.log("ERR setMyData", err)
		return e
	}
}

////////////////////////////////////////////////////////////////////////////////////////////////////////
export const getFormattedDate = (d, f) => {
	try{
		return (
			format(myTimestampToDate(d), f)
		)
	}catch(err){

		return (
			<FontAwesomeIcon icon="exclamation-triangle" />
		)
	}
}

////////////////////////////////////////////////////////////////////////////////////////////////////////
export const getTimeStr = (n, t) => {
	const num = n;
	const hours = (num / 60);
	const rhours = Math.floor(hours);
	const minutes = (hours - rhours) * 60;
	const rminutes = Math.round(minutes);
	return (<>
		{rhours > 0 ? <> {rhours} {t('global.labels.hours')}</> : ''}&nbsp;{rminutes > 0 ? <> {rminutes} {t('global.labels.min')}</> : ''}
	</>)
}

////////////////////////////////////////////////////////////////////////////////////////////////////////
export const myDateToTimestamp = (d) => {

	if (d) {
		let myd = d
		if (typeof d === 'string' || d instanceof String) {
			try {
				const tmp_d = parse(d, 'HH:mm', new Date())
				myd = firebase.firestore.Timestamp.fromDate(tmp_d)
			} catch (err) {

			}
		} else {
			try {
				try {
					//console.log(d)
					myd = d.toDate()
				} catch (err) {

				}
				myd = firebase.firestore.Timestamp.fromDate(myd)
			} catch (err) {
				//console.error(err)
			}

		}

		return myd
	} else {
		//console.error("EMPTY DATE")
		return ''
	}
}
////////////////////////////////////////////////////////////////////////////////////////////////////////
/**
 * return javascript Date() object or original object (new Date if empty)
 * @param {d} d date in Timestamp format (from firebase) 
 */
export const myTimestampToDate = (d) => {
	if (d) {
		let myd = d
		try {
			if (d._seconds) {
				d = new firebase.firestore.Timestamp(d._seconds, d._nanoseconds)
			}
			myd = d.toDate()
		} catch (err) {
			//			console.error("date error conversion", err, d, myd)
		}
		return myd
	} else {
		//	console.error("date error conversion(NO DATE)",d)
		return new Date()
	}
}


////////////////////////////////////////////////////////////////////////////////////////////////////////
export const toDateTime = (d) => {
	if (d && d.seconds) {
		var t = new Date(Date.UTC(1970, 0, 1)); // Epoch
		t.setUTCSeconds(d.seconds);
		return t;
	} else {
		return ''
	}
}




////////////////////////////////////////////////////////////////////////////////////////////////////////
export function onlyUnique(value, index, self) {
	return self.indexOf(value) === index;
}


////////////////////////////////////////////////////////////////////////////////////////////////////////
export const getFloatNumber = (n) => {
	if (typeof n === 'string') {
		return parseFloat(n.replace(/,/, '.'))
	} else if (isNaN(n)) {
		console.warn('NAN number', n)
		return 0
	} else {
		return parseFloat(n)
	}
}

////////////////////////////////////////////////////////////////////////////////////////////////////////
export const getFixedNumber = (n) => {
	try {
		return parseFloat(n).toFixed(2)
	} catch (err) {
		return n
	}
}

////////////////////////////////////////////////////////////////////////////////////////////////////////
export const getValue = (element, value, fallback) => {
	if (element) {
		return element[value]
	} else {
		return fallback || ''
	}
}

////////////////////////////////////////////////////////////////////////////////////////////////////////
export const getBaseObjectValue = (element, value, locale) => {
	try {
		return element[value][locale]

	} catch (err) {
		return ''
	}

}
export var stringToColour = function(str) {
	var hash = 0;
	for (let  i = 0; i < str.length; i++) {
	  hash = str.charCodeAt(i) + ((hash << 5) - hash);
	}
	var colour = '#';
	for (let  i = 0; i < 3; i++) {
	  var value = (hash >> (i * 8)) & 0xFF;
	  colour += ('00' + value.toString(16)).substr(-2);
	}
	return colour;
  }


function componentToHex(c) {
	var hex = c.toString(16);
	return hex.length === 1 ? "0" + hex : hex;
}

////////////////////////////////////////////////////////////////////////////////////////////////////////
export function rgba2hex(rgb) {
	if (typeof rgb === 'string') {
		return rgb.substring(0, 7)
	} else {
		let hex = ''
		try {
			hex = "#" + componentToHex(rgb.r) + componentToHex(rgb.g) + componentToHex(rgb.b);
		} catch (err) { }
		return hex;
	}
}


////////////////////////////////////////////////////////////////////////////////////////////////////////
export const getAmountValue = (value) => {
	if (!isNaN(value) && (typeof value === 'number' || typeof value === 'string')) {
		try {
			return parseFloat(value)
		} catch (err) {
			console.log("error parsing", err)
			return 0
		}
	} else {
		//console.log('return 0')
		return 0
	}
}


////////////////////////////////////////////////////////////////////////////////////////////////////////
export const propComparator = (propName) =>
	(a, b) => a[propName] === b[propName] ? 0 : a[propName] < b[propName] ? -1 : 1

////////////////////////////////////////////////////////////////////////////////////////////////////////
export function compareName(a, b) {
	if (a.name < b.name) {
		return -1;
	}
	if (a.name > b.name) {
		return 1;
	}
	return 0;
}

////////////////////////////////////////////////////////////////////////////////////////////////////////
export function compareNameInt(a, b) {
	try {
		if (isNaN(parseInt(a.name)) || isNaN(parseInt(b.name))) {
			if (a.name < b.name) {
				return -1;
			}
			if (a.name > b.name) {
				return 1;
			}
			return 0;
		} else {
			if (parseInt(a.name) < parseInt(b.name)) {
				return -1;
			}
			if (parseInt(a.name) > parseInt(b.name)) {
				return 1;
			}
			return 0;

		}
	} catch (err) {
		if (a.name < b.name) {
			return -1;
		}
		if (a.name > b.name) {
			return 1;
		}
		return 0;

	}

}

////////////////////////////////////////////////////////////////////////////////////////////////////////
export function compareNameB(locale) {
	return function (a, b) {

		if (a.name[locale] < b.name[locale]) {
			return -1;
		}
		if (a.name[locale] > b.name[locale]) {
			return 1;
		}
		return 0;
	}
}

////////////////////////////////////////////////////////////////////////////////////////////////////////
export function compareParam(a, b, param) {
	if (a[param] && b[param]) {
		if (a[param] < b[param]) {
			return -1;
		}
		if (a[param] > b[param]) {
			return 1;
		}
		return 0;

	} else {
		return -1;

	}
}

////////////////////////////////////////////////////////////////////////////////////////////////////////
export function compareDateParam(a, b) {
	if (myTimestampToDate(a.createdAt) < myTimestampToDate(b.createdAt)) {
		return -1;
	}
	if (myTimestampToDate(a.createdAt) > myTimestampToDate(b.createdAt)) {
		return 1;
	}
	return 0;
}

////////////////////////////////////////////////////////////////////////////////////////////////////////
export function compareEditDateParam(a, b) {
	if (myTimestampToDate(a.lastEdit) < myTimestampToDate(b.lastEdit)) {
		return -1;
	}
	if (myTimestampToDate(a.lastEdit) > myTimestampToDate(b.lastEdit)) {
		return 1;
	}
	return 0;
}
////////////////////////////////////////////////////////////////////////////////////////////////////////
export function compareStartDateParam(a, b) {
	if (myTimestampToDate(a.start) < myTimestampToDate(b.start)) {
		return -1;
	}
	if (myTimestampToDate(a.start) > myTimestampToDate(b.start)) {
		return 1;
	}
	return 0;
}
////////////////////////////////////////////////////////////////////////////////////////////////////////
export const propComparatorDate = (propName) =>
	(a, b) => myTimestampToDate(a[propName]) === myTimestampToDate(b[propName]) ? 0 : myTimestampToDate(a[propName]) < myTimestampToDate(b[propName]) ? -1 : 1

////////////////////////////////////////////////////////////////////////////////////////////////////////
export function compareDateCustomParam(a, b, param) {
	if (myTimestampToDate(a[param]) < myTimestampToDate(b[param])) {
		return -1;
	}
	if (myTimestampToDate(a[param]) > myTimestampToDate(b[param])) {
		return 1;
	}
	return 0;
}
////////////////////////////////////////////////////////////////////////////////////////////////////////
export function compareCode(a, b) {
	if (a.code < b.code) {
		return -1;
	}
	if (a.code > b.code) {
		return 1;
	}
	return 0;
}


////////////////////////////////////////////////////////////////////////////////////////////////////////
export function compareType(a, b) {
	if (a.type < b.type) {
		return -1;
	}
	if (a.type > b.type) {
		return 1;
	}
	return 0;
}


////////////////////////////////////////////////////////////////////////////////////////////////////////
export function compareOrder(a, b) {
	try {

		if (parseInt(a.order) < parseInt(b.order)) {
			return -1;
		}
		if (parseInt(a.order) > parseInt(b.order)) {
			return 1;
		}
		return 0;
	} catch (err) {
		console.log('no order')
		if (a.order < b.order) {
			return -1;
		}
		if (a.order > b.order) {
			return 1;
		}
		return 0;

	}
}


////////////////////////////////////////////////////////////////////////////////////////////////////////
export const getElementPreview = (i, size) => {
	const i_size = size || '50px'
	if (i && i.media_list) {
		for (let d = 0; d < i.media_list.length; d++) {
			if (i.media_list[d].type === 'image') {
				return (<RImage style={{ maxHeight: i_size }} src={i.media_list[d].url} fluid alt={i.name} />)
			}
		}
	}
	return (
		<>
		</>

	)
}
////////////////////////////////////////////////////////////////////////////////////////////////////////
export const getMediaIcon = (el) => {
	if (el.media_list) {
		return (

			<>
				{el.media_list.map((doc, index) => (
					<span className="mr-1" key={index}>
						{doc.type === 'image' ?
							<FontAwesomeIcon icon='image' />
							:
							<FontAwesomeIcon icon='file' />
						}
					</span>
				))}
			</>
		)
	} else {
		return (<></>)
	}

}



////////////////////////////////////////////////////////////////////////////////////////////////////////
export const getElementCount = ({ element_list, name, t }) => {
	if (element_list) {
		return (<>{element_list.length} {name}</>)
	} else {
		return (<>{0} {name}</>)

	}
}




////////////////////////////////////////////////////////////////////////////////////////////////////////
export const checkDepartmentPermission = ({ level, department_list, element_id }) => {
	for (const department of department_list) {
		if (level < 3) {

			if (department.parent_list[level] === element_id) {
				return true
			}
		} else if (level === 3) {
			if (department.id === element_id) {
				return true
			}
		}
	}
	return false


}



////////////////////////////////////////////////////////////////////////////////////////////////////////
export const getQualificationParam = (e, contract_list, param) => {
	try {
		const w = getSelectedElement(e.work_contract_id, contract_list, 'id')
		const q = getSelectedElement(e.qualification_id, w.qualification_list, 'id')
		return q[param]
	} catch (err) {
		console.log(err)
		return ''
	}

}



////////////////////////////////////////////////////////////////////////////////////////////////////////
export const filterWeekDay = function (_wd, start, custom_weekdays, room_id) {
	const wd = custom_weekdays && custom_weekdays[room_id] ? custom_weekdays[room_id] : _wd
	if (wd) {
		return wd.indexOf(getDay(myTimestampToDate(start)).toString()) !== -1
	} else {
		return false
	}
}




////////////////////////////////////////////////////////////////////////////////////////////////////////
export const getTicketReceiver = (m, messagegroup_list, param) => {
	if (m[param] && getSelectedElement(m[param], messagegroup_list, 'id')) {
		return (
			<>
				{getSelectedElement(m[param], messagegroup_list, 'id').name}
			</>
		)
	} else {
		return '---'
	}
}




////////////////////////////////////////////////////////////////////////////////////////////////////////
export const getPlanCode = (item_list_length, controlMode) => {
	try {
		return (item_list_length + 1).toString().padStart(3, "0")
	} catch (err) {
		return ""
	}
}

////////////////////////////////////////////////////////////////////////////////////////////////////////
export const getPlanStartDate = (start) => {
	let st = myTimestampToDate(start)
	st.setHours(0, 0, 0)
	return (firebase.firestore.Timestamp.fromDate(st))
}


////////////////////////////////////////////////////////////////////////////////////////////////////////
export const getPlanEndDate = (start, month_count) => {
	let end = addMonths(myTimestampToDate(start), month_count)
	end = endOfMonth(addMonths(myTimestampToDate(start), month_count))
	end.setHours(23)
	end.setMinutes(59)
	return (firebase.firestore.Timestamp.fromDate(end))
}

////////////////////////////////////////////////////////////////////////////////////////////////////////
export const getStructureFromWorkareas = (wa_id_list, workarea_dict) => {
	const st_list = wa_id_list.map(w => workarea_dict[w].structure_list)
	return st_list.flat()
}
////////////////////////////////////////////////////////////////////////////////////////////////////////
export const getMonthLength = (one_day) => {
	try {
		const dt = myTimestampToDate(one_day)
		const month = dt.getMonth()
		const year = dt.getFullYear()
		const d = new Date(year, month + 1, 0).getDate()
		return d
	} catch (err) {
		return 31
	}

}


/////////////////////////////////////////////////////////////////////////////////////////!SECTION
export const getObjectValue = (obj, val, param) => {
	try{
		return (obj[val][param])
	}catch(err){
		return ('')
	}
}

export function checkIfImageExists(url, callback) {
	const img = new Image();
	img.src = url;
	
	if (img.complete) {
	  callback(true);
	} else {
	  img.onload = () => {
		callback(true);
	  };
	  
	  img.onerror = () => {
		callback(false);
	  };
	}
  }



/* export const downloadFile =  async(refToFile) => {
	const storage = getStorage()
	const fileRef = ref(storage, refToFile)
	const blob = await getBlob(fileRef)
	const blobUrl = URL.createObjectURL(blob)
	const link = document.createElement('a')
	link.href = blobUrl
	link.download = 'filename.ext'
	link.click()
  }
   */