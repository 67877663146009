import React, { useState, useContext, useEffect, useLayoutEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Row, Col, Button, Modal, Form, Spinner, Dropdown, Image, ProgressBar, Badge } from 'react-bootstrap';
import { translate } from 'react-polyglot'

import DateTime from 'react-datetime';
import 'react-day-picker/lib/style.css';
import { format, getDay, differenceInMinutes, set, addHours } from 'date-fns'
import { el, it } from 'date-fns/locale'


import { ticketType_dict, messagePriority_dict } from "../components/constants/global_definitions"

import { firebaseAuth } from '../provider/AuthProvider'
import DomainContext from './contexts/domainContext'
import GlobalDataContext from './contexts/globaldataContext'

import AlertSection from "../components/AlertSection.js"

import { getSelectedElement, compareName, getValue, setMyDate, myTimestampToDate, getStructureFromWorkareas, checkComponentPresence, myDateToTimestamp } from "../util/ui_utils.js"


import { ServiceBadge, RoomInfoBadge, RiskareaBadge } from "../components/StructureGraphicElements"


import firebase from 'firebase/app'
import { addTicket as addTicket2 } from "../Firebase2/Storage2/dbTicket"
import { getRoomList as getRoomList2 } from '../Firebase2/Storage2/dbRoom';
import { addEvent } from '../Firebase2/Storage2/dbAgenda';
import { uploadFileComplete as uploadFileComplete2 } from '../Firebase2/Storage2/dbFileStorage';

import { addIntervention } from '../Firebase2/Storage2/dbIntervention'
import { FailureSelector } from './FailureSelector';


//const contract = JSON.parse(sessionStorage.getItem('contract'))
const section = 'ticket'
let load_page = false



//-----------------------------------------------------------------------------------------------------
function ModalTicket({ item, setItem, t, is_new, item_list, ui_list, type, parent_list, is_menu, canWrite }) {
	const { domain } = useContext(DomainContext)
	const { userData } = useContext(firebaseAuth)
	const { contractId, structureElements, all_workarea_dict, workarea_list } = useContext(GlobalDataContext)
	const structure_list = structureElements.structure_list


	const childRefAlert = useRef()


	const ticket_type_dict = ticketType_dict({ t })

	const newticket = {
		title: '',
		category: '',
		message_list: [],
		message: '',
		status_history: [],
		userId: localStorage.getItem('userId'),
		userName: userData.isGlobalAdmin ? 'admin' : userData.displaName || 'utente',
		sender_group: -1,
		receiver_group: -1,
		priority_level: 1,
		room_list: [],
		programmed_date: myDateToTimestamp(new Date()),
		programmed_start_time: myDateToTimestamp(new Date()),
		createdAt: setMyDate(new Date()),
		note: '',
		read: false
	}



	const onUpdateData = (data, b) => {
		//DEBUG: console.log("onUpdateData. DATA:", data, "   B: ", b)
	}

	const [showEdit, setShow] = useState(false)
	const [curElement, setCurrentElement] = useState(newticket)
	const [editedElement, setEditedElement] = useState(false)
	const [ticketType, setTicketType] = useState('request')

	const handleShowNew = (type) => {
		setTicketType(type)
		newticket.media_list = []
		if (parent_list) {
			console.log(parent_list, structureElements)
			const structure = getSelectedElement(parent_list[0], structure_list, 'id')
			const department = getSelectedElement(parent_list[3], structureElements.department_list, 'id')
			console.log(department)
			const el = parent_list[4]
			const w_list = getWorkareaListOneRoom(el, workarea_list)
			const w_id = w_list.length > 0 ? w_list[0].id : -1
			const r_list = [{ ...getSimpleData(el), w_list, workarea_id: w_id }]
			setCurrentElement({
				...newticket,
				type: type,
				structure: { id: structure.id, name: structure.name },
				department: { id: department.id, name: department.name },
				room: { id: el.id, name: el.name, parent_list: el.parent_list },
				room_list: r_list
			})
		} else {
			setCurrentElement({ ...newticket, type: type })
		}
		setShow(true)
		setEditedElement(false)
	}

	const handleShowEdit = () => {
		setCurrentElement(item)
		setShow(true)
		setTimeout(function () {
			setEditedElement(false)
		}, 500)
	}


	const onChangeHandler = (el) => {
		let { name, value, type } = el
		if (name === 'programmed_start_time' || name === 'programmed_date') {
			try {
				value = value.toDate()
			} catch (err) {
				console.error(err)
			}
		}
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = curElement[name] || []
			if (el.checked) {
				oldcheck = true
				/* 				if (oldcheck.indexOf(value) < 0) {
									oldcheck.push(value)
								} */
			} else {
				/* 				if (oldcheck.indexOf(value) >= 0) {
									oldcheck.splice(oldcheck.indexOf(value), 1)
								} */
				oldcheck = false
			}
			value = oldcheck
		} else if (type === 'file') {
			console.log(el.files)
			value = el.files
			name = 'file'
		}
		console.log(name, value)
		setCurrentElement({ ...curElement, [name]: value })
		setEditedElement(true)
	}



	const [uploadingState, setUploadingState] = useState(-1)
	const cb_progress = (status, byte, total) => {
		console.log(status, byte, total)
		try {
			setUploadingState(100 * byte / total)
		} catch (e) {
			console.warn("handleSaveMedia - error: ", e)
		}
	}

	const handleClose = () => setShow(false);



	const checkValidity = () => {
		let disabled = false
		if (curElement.receiver_group === -1 || curElement.receiver_group === '-1') {
			disabled = true
		}
		if (curElement.message.length === 0) {
			disabled = true
		}
		if (curElement.type === 'request') {
			 			if (curElement.room_list.length === 0) {
							disabled = true
						}
			if (!curElement.service || !curElement.service.id) {
				disabled = true
			}
		}
		console.log("CHECK", disabled)
		return disabled
	}

	const handleSave = async () => {
		const message = {
			createdAt: curElement.createdAt,
			priority_level: curElement.priority_level,
			message: curElement.message,
			status: 'sent',
			userId: localStorage.getItem('userId'),
			userName: userData.isGlobalAdmin ? 'admin' : userData.displayName || 'utente'
		}


		const cb_save = async (url, tracker) => {
			try {
				if (url) {
					message.media = url
					message.media_tracker = tracker
				}
				console.log(message)

				const userName = userData.isGlobalAdmin ? 'admin' : userData.displayName || 'utente'
				let status_history = curElement.type === 'request' && curElement.urgent_execution
					?
					[{ code_id: 'sent', status_date: curElement.createdAt }, { code_id: 'assigned', status_date: curElement.createdAt }]
					:
					[{ code_id: 'sent', status_date: curElement.createdAt }]
				let _newticket = {
					...curElement,
					programmed_date: myDateToTimestamp(curElement.programmed_date),
					programmed_start_time: myDateToTimestamp(curElement.programmed_start_time),
					lastEdit: curElement.createdAt,
					userName: userName,
					message_list: [message], status_history: status_history
				}

				delete _newticket.file
				console.log("TS", _newticket)
				let return_data = await addTicket2(domain, contractId, _newticket)
				if (return_data && return_data.error) {
					console.log(return_data, message)
					childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
				} else {
					console.log(return_data)
					const newlist = [return_data, ...item_list]
					setItem(newlist)

					const newEvent = {
						start: myDateToTimestamp(new Date()),
						end: myDateToTimestamp(new Date()),
						extendedProps: {
							type: ticket_type_dict[ticketType].event,
							message_id: return_data.id,
							sender_group: curElement.sender_group,
							receiver_group: curElement.receiver_group,
							userId: curElement.userId
						}
					};
					const return_dataTicketEvent = addEvent(domain, contractId, newEvent)
					if (return_dataTicketEvent.error) {
						console.error("errore nell'aggiunta dell'evento")
					} else {
						childRefAlert.current.addAlert('success', 'invio effettuato correttamente')
						if (curElement.type === 'request' && curElement.urgent_execution) {
							let currentEditIntervention = { ...new_intervention }
							currentEditIntervention.type = 'requested'
							currentEditIntervention.frequency_mode = 'requested'
							currentEditIntervention.service = curElement.service
							let start = set(myTimestampToDate(curElement.programmed_date), { hours: myTimestampToDate(curElement.programmed_start_time).getHours(), minutes: myTimestampToDate(curElement.programmed_start_time).getMinutes(), seconds: 0 })
							let end = set(myTimestampToDate(curElement.programmed_date), { hours: 23, minutes: 59, seconds: 0 })
							currentEditIntervention.start = start
							currentEditIntervention.end = end

							//updateInterventionDict(return_data.id, currentEditIntervention)
							console.log('new intervention')

							for (const room of curElement.room_list) {
								const real_room = structureElements.all_room_dict[room.id]
								currentEditIntervention = {
									...currentEditIntervention,
									structure_list: [real_room.parent_list[0]],
									pavillon_list: [real_room.parent_list[1]],
									room_list: [{ id: real_room.id, name: real_room.name, roomtype: { id: real_room.roomtype.id }, parent_list: real_room.parent_list, cdc: real_room.cdc || '', size: real_room.size }],
									floor_list: [real_room.parent_list[2]],
									size: real_room.size,
									room_count: 1,
									department_list: [real_room.parent_list[3]]
								}
								const return_data_i = await addIntervention(domain, contractId, currentEditIntervention)
								console.log("intervention", room.id, currentEditIntervention)
								const newEvent = {
									start: currentEditIntervention.start,
									end: currentEditIntervention.end,
									extendedProps: {
										type: 'requested_intervention',
										workarea_id: room.workarea_id,
										workarea_name: all_workarea_dict[room.workarea_id] ? all_workarea_dict[room.workarea_id].name : '',
										intervention_id: return_data_i.id,
										service_id: curElement.service.id,
										structure_list: [real_room.parent_list[0]],
										pavillon_list: [real_room.parent_list[1]],
										floor_list: [real_room.parent_list[2]],
										department_list: [real_room.parent_list[3]],
										source_message_id: return_data.id,
										requested_by: userName,
										createdAt: myDateToTimestamp(new Date()),
										assigned_by: userName,
										note: curElement.note ? curElement.note : ''
									}
								};
								const ret_newrequestcalendarEv = await addEvent(domain, contractId, newEvent)
								console.log(ret_newrequestcalendarEv)
							}
						}
					}
					setShow(false);
				}

			} catch (err) {
					childRefAlert.current.addAlert('danger', t('global.amessages.error') + err)

			}
		}

		if (curElement.file) {
			const mypath = contractId + '/' + section
			let _el = { ...curElement }
			delete _el.file
			const mediainfo = { description: 'allegato', source_name: curElement.file[0].name, section: section, item_reference: _el }
			console.log(mediainfo, curElement.file[0])
			let file_data = uploadFileComplete2(domain, '0', mypath, curElement.file[0], curElement.file[0].contentType, mediainfo, { cbProgress: cb_progress, cbSetDownload: cb_save })
			console.log("FILE", file_data)

		} else {
			cb_save()
		}

	}








	return (
		<>
			{is_new ?
				(is_menu ?
					<Dropdown.Item eventKey={is_menu} onClick={() => handleShowNew(type)} >
						{ticket_type_dict[type].icon} {ticket_type_dict[type].label_new}
					</Dropdown.Item>
					:
					<Button variant="outline-primary" onClick={() => handleShowNew(type)}> {ticket_type_dict[type].label_new}</Button>
				)
				: <Button className="mr-1" variant="info" size="sm" onClick={() => handleShowEdit()}><FontAwesomeIcon icon="pencil-alt" /> </Button>
			}
			<Modal show={showEdit} onHide={handleClose} dialogClassName="custom-modal" className="full-modal bg-secondary" size="xl" backdrop="static">
				<AlertSection ref={childRefAlert} />
				<Modal.Header closeButton className={editedElement ? "bg-warning" : ""} >
					<Modal.Title>
						{is_new ?
							<span className='text-primary'>

								<FontAwesomeIcon icon='plus-square' /> &nbsp;
								{ticket_type_dict[ticketType].label_new}
							</span>
							:
							<span className='text-info'>
								<FontAwesomeIcon icon="pencil-alt" />&nbsp;
								Modifica
							</span>
						}&nbsp;
						{editedElement ? <FontAwesomeIcon icon="save" /> : ''}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<ElementFormDetails element={curElement} t={t} ui_list={ui_list} functions={{ edit: onChangeHandler, updateData: onUpdateData }} parent_list={parent_list} structureElements={structureElements} uploadingState={uploadingState} />
						<Row>
							<Col><i>{format(myTimestampToDate(curElement.createdAt), 'dd/MM/yyyy HH:mm')}</i></Col>
							{userData ?
								<Col className="text-right">
									<i>
										inviato da: {userData.isGlobalAdmin ? 'admin' : userData.displayName}
									</i>
								</Col>
								: 'utente non rilevato'}

						</Row>
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						{t('modal.close')}
					</Button>
					<Button disabled={checkValidity()} className={editedElement ? "btn-warning" : "btn-primary"} form="companyForm" onClick={handleSave}>
						<FontAwesomeIcon icon="save" /> {t('modal.save')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}






const new_intervention = {
	name: '',
	room_list: [],
	group_name: '',
	group_id: -1,
	start: firebase.firestore.Timestamp.fromDate(set(new Date(), { hours: 8, minutes: 0, seconds: 0 })),
	end: firebase.firestore.Timestamp.fromDate(set(new Date(), { hours: 10, minutes: 0, seconds: 0 })),
	weekdays: [],
	service: {},
	frequency_mode: 'requested',
	operating_units: 1,
	max_check_delay: 0,
	operation_list: [],
	intervention_length: 1,
	size: 0,
	use_activity_period: false,
	cart_element_list: [],
	periodic_data: {
		date_list: [],
		start: '6:00',
		end: '8:00'
	}
}


const getElementName = (id, f_dict) => {
	try {
		console.log("ELEM NAME", f_dict[id])
		return f_dict[id].name
	} catch (err) {
		console.log("ERR", err, f_dict, id)
		return '---'
	}

}


////////////////////////////////////////////////////////////////////////////////////////////////////////
function ElementFormDetails({ element, functions, ui_list, t, uploadingState, parent_list }) {
	const { domain } = useContext(DomainContext)
	const { contract, risk_area_dict, contractId, roomtype_dict, structureElements, all_workarea_dict, sendmessagegroup_list, service_dict, operation_dict, workarea_list } = useContext(GlobalDataContext)
	const { userData } = useContext(firebaseAuth)
	const [externalTrigger, setExternalTrigger] = useState(false)
	const [showTicketRoomEdit, setShowTicketRoomEdit] = useState(false)


	const [service_list, setServiceList] = useState([])
	const [operation_list, setOperationList] = useState([])

	const [_str_list, setStructureList] = useState(structureElements.structure_list)
	const structure_list = structureElements.structure_list
	const priority_dict = messagePriority_dict({ t })


	const [loading, setLoading] = useState(false)
	//const { locale } = useContext(LocaleContext)
	const [department_list, setDepartmentList] = useState([])
	const [room_list, setRoomList] = useState([])
	let permission_department_list = []
	let permission_structure_list = []


	const initValues = async () => {
		load_page = true
		let _structure_list = [..._str_list]
		if (userData && userData.contract_dict && userData.contract_dict[contractId]) {
			if (userData.contract_dict[contractId][0].mode === 'workarea') {

				const permission_workarea_list = userData.contract_dict[contractId].map(cl => cl.workarea.id)
				console.log("permission workarea_list", permission_workarea_list, all_workarea_dict, userData.contract_dict[contractId][0].workarea)
				permission_structure_list = getStructureFromWorkareas(permission_workarea_list, all_workarea_dict)
				console.log("permission_structure_list", permission_structure_list)
				_structure_list = _structure_list.filter(s => permission_structure_list.indexOf(s.id) !== -1)
			} else {
				if (userData.contract_dict[contractId][0].structure) {
					if (userData.contract_dict[contractId][0].department && userData.contract_dict[contractId][0].department.id) {
						permission_department_list = userData.contract_dict[contractId].map(cl => cl.department.id)
						console.log("permission department_list", permission_department_list, userData.contract_dict[contractId][0].department)
					}
					if (userData.contract_dict[contractId][0].structure && userData.contract_dict[contractId][0].structure.id) {
						permission_structure_list = userData.contract_dict[contractId].map(cl => cl.structure.id)
						_structure_list = _str_list.filter(s => permission_structure_list.indexOf(s.id) !== -1)

					}
					console.log("permission structure_list", permission_structure_list)
				}
			}
		}

		setStructureList(_structure_list)
		if (_str_list) {
			onChangeStructure(_structure_list[0].id)
		}
		let servicelist = Object.values(service_dict)
		servicelist = servicelist.filter(s => s.type === 'requested').sort(compareName)
		setServiceList(servicelist.sort(compareName))
		let operationlist = Object.values(operation_dict)
		operationlist = operationlist.filter(s => (s.type_list && s.type_list.indexOf('requested') >= 0))
		setOperationList(operationlist.sort(compareName))
	}




	useEffect(() => {
		initValues()
	}, [])





	const onChangeStructure = async (el) => {
		//	setLoading(true)
		if (el && el.value) {

			console.log(el, el.value)
			const structure = getSelectedElement(el.value, _str_list, 'id')
			functions.edit({ name: 'structure', value: { id: structure.id, name: structure.name } })
			let return_data = structureElements.department_list.filter(d => d.parent_list[0] === el.value)

			//		let return_data = await getAllDepartmentInStructure(el.value)
			setLoading(true)
			if (return_data && return_data.error) {
				//				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				if (userData.contract_dict[contractId] && userData.contract_dict[contractId][0].department.id) {
					const filter_department_list = userData.contract_dict[contractId].map(cl => cl.department.id)
					console.log(filter_department_list)
					return_data = return_data.filter(d => filter_department_list.indexOf(d.id) !== -1)
					console.log("DEPARMTNET LIST----", return_data)
				}
				return_data = return_data.map(d => ({ ...d, floor_name: getElementName(d.parent_list[2], structureElements.all_floor_dict), pavillon_name: getElementName(d.parent_list[1], structureElements.all_pavillon_dict) }))
				setDepartmentList(return_data)
			}
		}
		setLoading(false)
	}


	const onChangeDepartment = async (el) => {
		//	setLoading(true)
		//setRoomList([])
		const department = getSelectedElement(el.value, department_list, 'id')
		console.log(el.value, department)
		setShowTicketRoomEdit(false)
		if (department.id) {
			setLoading(true)
			let return_data = await getRoomList2(domain, contractId, department.parent_list[0], department.parent_list[1], department.parent_list[2], el.value)
			return_data = return_data.sort(compareName)
			console.log("room_list", return_data)
			department.referent_list = department.referent_list || []
			functions.edit({ name: 'department', value: { id: department.id, name: department.name } })
			if (return_data && return_data.error) {
				console.log(return_data)
				//				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
				setRoomList(return_data)
			} else {
				setRoomList(return_data)
				setShowTicketRoomEdit(true)
				//			console.log(return_data)
			}
			setLoading(false)
		}
	}


	const onChangeRoom = (el) => {
		console.log(el)
		const room = getSelectedElement(el.value, room_list, 'id')
		functions.edit({ name: 'room', value: { id: room.id, name: room.name } })
	}
	const handleCloseEvent = () => setShowTicketRoomEdit(false);
	const handleOpenEvent = () => {
		//setCurEvent(editedEvent)
		setShowTicketRoomEdit(true)
	}



	//---------------generic function
	const handleAddEl = (el, section) => {
		console.log("ADD EL", section, el)
		let editlist = element.room_list
		const w_list = getWorkareaListOneRoom(el, workarea_list)
		const w_id = w_list.length > 0 ? w_list[0].id : -1
		if (editlist) {
			const c = checkComponentPresence(el, element.room_list, 'id')
			if (c < 0) {
				editlist.push({ ...el, w_list, workarea_id: w_id })
			}
		} else {
			editlist = [{ ...el, w_list, workarea_id: w_id }]
		}
		functions.edit({ name: 'room_list', value: editlist })
	}

	const handleDeleteEl = (el) => {
		const cb = () => {
			let editlist = element.room_list
			const c = checkComponentPresence(el, editlist, 'id')
			console.log("PRE_DEL", editlist)
			editlist.splice(c, 1)
			console.log("POST_DEL", editlist)
			functions.edit({ name: 'room_list', value: editlist })
		}
		cb()

	}

	const onChangeHandlerRoom = (e, index) => {
		let editlist = [...element.room_list]
		editlist[index].workarea_id = e.value
		functions.edit({ name: 'room_list', value: editlist })
	}

	const OneTicketRoom = ({ _room, k, element_list, section, showComponentEdit }) => {
		return (

			<Row key={k} className="p-1">
				{showTicketRoomEdit ?
					<Col>
						<RoomInfoBadge parent_list={_room.parent_list} structure_list={ui_list.structure_list} pavillon_dict={structureElements.all_pavillon_dict} floor_dict={structureElements.all_floor_dict} department_dict={structureElements.all_department_dict} />
						{_room.name}
					</Col>
					:
					<Col>
						<Row>
							<Col>
								{_room.name}
							</Col>
						</Row>
						<Row>
							<Col className="section_title text-muted">
								<RoomInfoBadge parent_list={_room.parent_list} expanded={true} structure_list={ui_list.structure_list} pavillon_dict={structureElements.all_pavillon_dict} floor_dict={structureElements.all_floor_dict} department_dict={structureElements.all_department_dict} />
							</Col>
						</Row>
					</Col>
				}
				<Col className="text-muted" sm="2">
					{roomtype_dict[_room.roomtype.id].name}
				</Col>
				<Col sm="1" className="text-center">{_room.size}</Col>
				<Col sm="1" className="text-center">
					<RiskareaBadge riskarea={risk_area_dict[_room.risk_area.Id]} />
				</Col>
				<Col sm="2">
					{_room.w_list.length > 0 ?
						<Form.Control as="select" className="mb-2" name="workarea_id" value={_room.workarea_id} onChange={(event) => onChangeHandlerRoom(event.currentTarget, k)} >
							<option value="-1" >{t('ticket.labels.select_workarea')}</option>
							{_room.w_list.map((w, kk) => (
								<option key={kk} value={w.id} >{w.name}</option>
							))}
						</Form.Control>
						: <i>
							Locale non inserito in nessuna area di lavoro
						</i>}
				</Col>
				<Col sm="1" >
					<Button size="sm" variant="outline-danger"><FontAwesomeIcon icon="trash" onClick={() => handleDeleteEl(_room)} /> </Button>
				</Col>
			</Row>
		)

	}


	const OneRoom = ({ _room, k }) => {
		return (
			<Row key={k} className="border-bottom p-1">
				<Col sm="1" className="p-0 text-center">
					{checkComponentPresence(_room, element.room_list, 'id') !== -1 ?
						<Button size="sm" variant="secondary" disabled><FontAwesomeIcon icon="check" /><FontAwesomeIcon icon="plus-square" /> </Button>
						:
						<Button size="sm" variant="light" onClick={() => handleAddEl(getSimpleData(_room))}><FontAwesomeIcon className="text-warning" icon="arrow-left" />&nbsp;<FontAwesomeIcon icon="plus-square" /> </Button>
					}
				</Col>
				<Col>
					<RoomInfoBadge parent_list={_room.parent_list} structure_list={ui_list.structure_list} pavillon_dict={structureElements.all_pavillon_dict} floor_dict={structureElements.all_floor_dict} department_dict={structureElements.all_department_dict} />
					{_room.name}
				</Col>
				<Col className="text-muted" sm="2">
					{roomtype_dict[_room.roomtype.id].name}
				</Col>
				<Col sm="1" className="text-center">{_room.size}</Col>
				<Col sm="1 text-center">
					<RiskareaBadge riskarea={risk_area_dict[_room.risk_area.Id]} />
				</Col>
			</Row>
		)
	}




	return (
		<>
			{userData.messagegroups && userData.messagegroups.length > 0 &&
				<Form.Group as={Row} controlId="roomtypeName" className="border-bottom">
					<Form.Label column sm="2">{t('ticket.labels.send_as_group')}</Form.Label>
					<Col>
						<Form.Check type="radio" name="send_as_group" value={true} checked={element.send_as_group} label={t('global.labels.yes')} onChange={(event) => functions.edit(event.currentTarget)} />
						<Form.Check type="radio" name="send_as_group" value={false} checked={!element.send_as_group} label={t('global.labels.no')} onChange={(event) => functions.edit(event.currentTarget)} />
					</Col>
					{element.send_as_group ?
						<Col sm="8">
							<Form.Control as="select" className="mb-2" name="sender_group" onChange={(event) => functions.edit(event.currentTarget)} >
								<option value="-1" >{t('ticekt.labels.select_send_group')}</option>
								{userData.messagegroups.map((f, k) => (
									<option key={k} value={f.message_group_id} >{f.message_group_name}</option>
								))}
							</Form.Control>
						</Col>
						: ''}
				</Form.Group>
			}
			{element.type === 'request' ?
				<>
					<Form.Group as={Row} controlId="formStructureStructure" className="border-bottom">
						<Form.Label column className={!element.service || !element.service.id ? 'text-danger' : ''} sm="2">{t('global.labels.service')} </Form.Label>
						<Col sm="4">
							<Form.Control as="select" className="mb-2" name="service" value={getValue(element.service, 'id')}
								onChange={(event) => functions.edit({ name: 'service', type: 'select', value: service_dict[event.target.value] ? { id: service_dict[event.target.value].id, name: service_dict[event.target.value].name } : {} })} >
								<option value="-1" >{t('roomtype.labels.select_service')}</option>
								{service_list

									? [Object(service_list).map((f, k) => (
										<option key={k} value={f.id}>{f.name} </option>
									))]

									: ''
								}
							</Form.Control>
						</Col>
						<Col sm="6">
							{element.service &&
								<ServiceBadge service={service_dict[element.service.id]} freq={true} />
							}

						</Col>
					</Form.Group>
					{/* 					<Form.Group as={Row} controlId="formStructureStructure" className="border-bottom">
						<Form.Label column sm="2">{t('global.labels.operations')} </Form.Label>
						<Col sm="4">
							{operation_list && operation_list.length > 0 ?
								<>
									<Form.Control as="select" className="mb-2" name="operation" value={getValue(element.operation, 'id')} onChange={(event) => functions.edit({ name: 'operation', type: 'select', value: { id: operation_dict[event.target.value].id, name: operation_dict[event.target.value].name } })} >
										<option value="-1" >{t('roomtype.labels.select_operation')}</option>
										{Object(operation_list).map((f, k) => (
											<option key={k} value={f.id}>{f.name} </option>
										))}
									</Form.Control>
								</>
								: <>
									Nessuna operazione a richiesta configurata

								</>}
						</Col>
					</Form.Group> */}
				</>
				: <></>}
			{!parent_list ?
				<>
					<Form.Group as={Row} controlId="formStructureStructure" className="border-bottom" >
						<Form.Label column sm="2">{t('global.labels.room')}
							{loading ? <Spinner animation="border" role="status">
								<span className="sr-only">Loading...</span>
							</Spinner> : ''}</Form.Label>
						<Col sm="3">
							<Form.Control as="select" className="mb-2" name="structure" onChange={(event) => onChangeStructure(event.currentTarget)} >
								<option value="-1" >{t('structure.labels.select_structure')}</option>
								{_str_list && _str_list.length > 0
									? [_str_list.map((f, k) => (
										<option key={k} value={f.id} >{f.name}</option>
									))]
									: ''
								}
							</Form.Control>
						</Col>
						<Col sm="3">
							<Form.Control as="select" className="mb-2" name="department" onChange={(event) => onChangeDepartment(event.currentTarget)} >
								<option value="-1" >{t('structure.labels.select_department')}</option>
								<option value="0" >{t('structure.labels.all_department')}</option>
								{department_list
									? [Object(department_list).map((f, k) => (
										<option key={k} value={f.id} >{f.pavillon_name} / {f.floor_name} / {f.name}</option>
									))]
									: ''
								}
							</Form.Control>
						</Col>
						{/* <Col sm="3">
							{element.type !== 'request' ?
								<Form.Control as="select" className="mb-2" name="room" onChange={(event) => onChangeRoom(event.currentTarget)} >
									<option value="-1" >{t('structure.labels.select_room')}</option>
									<option value="0" >{t('structure.labels.all_rooms')}</option>
									{room_list
										? [Object(room_list).map((f, k) => (
											<option key={k} value={f.id} >{f.name} {getRoomtypeName(f.roomtype.id)}</option>
										))]
										: ''
									}
								</Form.Control>
								:
								<Col className="text-right">
									{element.department ?
										<>
											{showTicketRoomEdit ?
												<Button variant="outline-secondary" size="sm" onClick={() => handleCloseEvent()}><FontAwesomeIcon icon="check" /> {t('ticket.labels.room_modend')}</Button>
												:
												<Button variant="outline-primary" size="sm" onClick={() => handleOpenEvent()}><FontAwesomeIcon icon="pencil-alt" /> {t('ticket.labels.room_mod')}</Button>
											}
										</>
										: <></>
									}
								</Col>
							}
						</Col> */}
					</Form.Group>
					<Row className="mb-1">
					</Row>
					<Row className="border-bottom mb-1" >



						{element.room_list.length > 0 ?
							<Col className="border-right">
								<Row className="p-1 text-light section_title">
									<Col className="ml-1 text-info" >{t('workarea.labels.roomlist')} {
										element.room_list && element.room_list.length > 0 ?
											<Badge variant="info">{element.room_list.length}</Badge>
											: ''
									}</Col>
								</Row>
								<Row className="p-1 section_title mb-1">
									<Col className="ml-1 bg-light text-secondary" >
										<Row>
											<Col>
												{t('global.labels.name')}
											</Col>
										</Row>
										{!showTicketRoomEdit ?
											<Row>
												<Col>
													{t('structure.labels.structure')}
												</Col>
												<Col>
													{t('global.labels.pavillon')}
												</Col>
												<Col>
													{t('structure.labels.floor')}
												</Col>
												<Col>
													{t('global.labels.department')}
												</Col>
											</Row>
											: <></>}
									</Col>
									<Col className="ml-1 bg-light text-secondary" sm="2">{t('structures.labels.room_type')}</Col>
									<Col className="ml-1 bg-light text-secondary text-center" sm="1" >m<sup>2</sup></Col>
									<Col className="ml-1 bg-light text-secondary text-center" sm="1" >{contract.type === 'civil' ? t('global.labels.standard_cleaning') : t('global.labels.risk_area')}</Col>
									<Col className="ml-1 bg-light text-secondary text-center" sm="2">{t('global.labels.workarea')}</Col>
									<Col className="ml-1 bg-light text-secondary text-center" sm="1">{t('global.labels.actions')}</Col>
								</Row>
								{element.room_list.map((i, key) => (

									<OneTicketRoom _room={i} key={key} k={key} showComponentEdit={showTicketRoomEdit} element_list={element.room_list} />

								))}

							</Col>

							:
							element.type === 'request' ?
								<Col className="font-italic text-danger">E' necessario selezionare almeno un locale</Col>
								:
								<Col className="font-italic text-warning">Nessun locale selezionato. Il messaggio sarà relativo a tutto il reparto o a tutta la struttura selezionata</Col>
						}
						{showTicketRoomEdit
							?
							<>
								<Col sm="1"></Col>
								<Col className="border-left editSection">
									{room_list ?
										<>
											<Row className="p-1 section_title mb-1">
												<Col className="ml-1 bg-light text-secondaryi text-center" sm="1">{t('global.labels.add')}</Col>
												<Col className="ml-1 bg-light text-secondary">{t('global.labels.name')}</Col>
												<Col className="ml-1 bg-light text-secondary" sm="2">{t('structures.labels.room_type')}</Col>
												<Col className="ml-1 bg-light text-secondary text-center" sm="1" >m<sup>2</sup></Col>
												<Col className="ml-1 bg-light text-secondary text-center" sm="2" >
													{contract && contract.type === 'civil' ?
														t('global.labels.standard_cleaning')
														:
														t('global.labels.risk_area')
													}
												</Col>
											</Row>
											{Object(room_list).map((i, key) => (
												<OneRoom _room={i} key={key} />
											))}
										</>
										: <> { }</>
									}
								</Col>
							</>
							: ''
						}



					</Row>
				</>
				: <Row className="border-bottom mb-1 pb-1">
					<Col>
						<i>Struttura:</i> {element.structure.name}
					</Col>
					<Col>
						<i>Reparto:</i> {element.department.name}
					</Col>
					<Col>
						<i>Locale:</i> {element.room.name}
					</Col>
				</Row>}
			<Form.Group as={Row} controlId="contractName" className="border-bottom mb-1">
				<Form.Label column sm="2">{t('ticket.labels.priority')}</Form.Label>
				<Col sm="4">
					<Form.Control as="select" className="mb-2" value={element.priority_level} name="priority_level" onChange={(event) => functions.edit(event.currentTarget)} >

						<option value="3" >alta</option>
						<option value="2" >media</option>
						<option value="1" >bassa</option>
					</Form.Control>
				</Col>
			</Form.Group>
			{element.type === 'request' ?
				<>
					<Form.Group as={Row} className="border-bottom">
						<Col sm="4">
							<Form.Check type="checkbox" className="ml-2" name="urgent_execution" value="" checked={element.urgent_execution} label={t('ticket.labels.direct_planning')} onChange={(event) => functions.edit(event.currentTarget)} />
						</Col>
						{element.urgent_execution ?
							<>
								<Col>
									<DateTime initialValue={myTimestampToDate(element.programmed_date)} dateFormat={'DD/MM/YYYY'} timeFormat={false} name="programmed_date" locale="it" closeOnSelect={true} onChange={(e) => functions.edit({ name: 'programmed_date', value: e })} />
									<Form.Text className="text-muted">
										{t('ticket.labels.programmed_date')}
									</Form.Text>
								</Col>
								<Col>
									<DateTime initialValue={myTimestampToDate(element.programmed_start_time)} dateFormat={false} timeFormat='HH:mm' name="programmed_start_time" locale="it" closeOnSelect={true} onChange={(e) => functions.edit({ name: 'programmed_start_time', value: e })} />
									<Form.Text className="text-muted">
										{t('ticket.labels.programmed_start')}
									</Form.Text>
								</Col>
							</>
							: ''}
					</Form.Group>
					<Form.Group as={Row} className="border-bottom mb-1">
						<Form.Label column sm="2">{t('ticket.labels.intervention_note')}</Form.Label>
						<Col sm="8">
							<Form.Control as="textarea" rows="3" name="note" defaultValue={element.note} onChange={(event) => functions.edit(event.currentTarget)} className="mb-2" />
						</Col>
					</Form.Group>
				</>
				: <></>}

			<Form.Group as={Row} className="border-bottom mb-1">
				<Form.Label column className={element.receiver_group === -1 || element.receiver_group === '-1' ? 'text-danger' : ''} sm="2">{t('ticket.labels.receiver')}</Form.Label>
				<Col sm="8">
					<Form.Control as="select" className="mb-2" defaultValue="-1" name="receiver_group" onChange={(event) => functions.edit(event.currentTarget)} >
						<option value="-1" >{t('ticket.labels.select_receiver')}</option>
						{sendmessagegroup_list
							? [sendmessagegroup_list.map((r, k) => (
								<option key={k} value={r.id} >{r.name}</option>
							))]
							: ''
						}
					</Form.Control>
				</Col>
			</Form.Group>
			<Form.Group as={Row} className="border-bottom">
				<Form.Label column sm="2">{t('ticket.labels.media')}</Form.Label>
				<Col>
					<Form.Control type="file" name="file" onChange={(event) => functions.edit(event.target)} />
				</Col>
				<Col>
					{element.file
						?
						<>
							<Button variant="outline-danger" onClick={() => functions.edit({ name: 'file', value: '' })}  ><FontAwesomeIcon icon="trash" /></Button>
						</>
						: ''}
				</Col>
			</Form.Group>
			{uploadingState > -1 ?
				<Row>
					<Col>
						<ProgressBar animated now={uploadingState} label={`${uploadingState.toFixed(1)}%`} />
					</Col>
				</Row>
				: <></>}

			{element.type === 'failure' ?
				<FailureSelector element={element} ui_list={ui_list} onChangeHandler={functions.edit} />
				:
				<Form.Group as={Row} className="border-bottom mb-1">
					<Form.Label column className={element.message.length === 0 && 'text-danger'} sm="2">{t('ticket.labels.message')}</Form.Label>
					<Col sm="8">
						<Form.Control as="textarea" rows="3" name="message" defaultValue={element.message} onChange={(event) => functions.edit(event.currentTarget)} className="mb-2" />
					</Col>
				</Form.Group>
			}

		</>
	)
}
export default translate()(ModalTicket)




const getSimpleData = (_room) => {
	return {
		id: _room.id,
		parent_list: _room.parent_list,
		name: _room.name,
		mq: _room.mq,
		risk_area: _room.risk_area,
		roomtype: _room.roomtype,
		workarea_id: _room.workarea_id,
	}
}
//---------------
const getWorkareaListOneRoom = (el, workarea_list) => {
	const w_list = workarea_list.filter(w => w.room_list.filter(r => r.id === el.id).length > 0)
	return w_list

}


