import React, { useState, useContext, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Row, Col, Button, OverlayTrigger, Tooltip, Modal, Form, FormControl, InputGroup, Nav, Tab, Badge, Spinner, ButtonGroup, DropdownButton, Popover, ListGroup } from 'react-bootstrap';
import { format, setHours, setMinutes, setSeconds, differenceInCalendarDays, differenceInDays } from 'date-fns'
import { it } from 'date-fns/locale'
import { translate } from 'react-polyglot'
import QRCode from 'qrcode.react'
import DateTime from 'react-datetime';
import firebase from 'firebase/app'

import { roomStatus_dict, roomTypeParams_dict, wallMaterials_dict } from "../components/constants/global_definitions"

import LocaleContext from './contexts/locale.js'
import DomainContext from './contexts/domainContext'
import EditContext from "../components/contexts/edit.js"
import GlobalDataContext from './contexts/globaldataContext.js';

import { makeid, checkComponentPresence, getSelectedElement, getFloatNumber, myTimestampToDate, rgba2hex, getValue, getBaseObjectValue, isEditable, propComparator, checkDatePresence, getTimeValue, getFormattedDate, getSelectedElementB } from "../util/ui_utils.js"
import { ElementFormComposition } from '../components/RoomComposition'
import AlertSection from "../components/AlertSection.js"
import { MultipleToggleEdit, renderItemNote, ServiceBadge, WeekDays, renderActivityPeriodListPopover, Frequency, ResultBar } from './StructureGraphicElements.js'
import ElementFormMedia from "../components/FormMedia.js"
import ModalTicket from '../components/ModalTicket.js';
import ModalRoomPreview from './ModalRoomPreview.js';
import { ModalProcessCheck } from "../components/ModalProcessCheck"
import { ServiceCheckPreview } from "../components/CheckPreview"
import { PathName, checkNextUpdate } from './modal/modalsection/sectionModalRoom';
import { ElementFormParamHistory } from './modal/modalsection/sectionModalRoomHistory';
import { ElementFormFrequencySummary } from './modal/modalsection/sectionModalRoomFrequency';


import { addRoom as addRoom2, editRoom as editRoom2, getRoomById } from '../Firebase2/Storage2/dbRoom'
import { processRoomsStatus, reconcileRooms } from '../components/FirebaseApi/roomStatus_api'
import FrequencySelector from './FrequencySelector';

import { getInterventionLogListRoom } from '../Firebase2/Storage2/dbInterventionLog';
import { getCheckResultListByRoom, getLastCheckResultByRoom } from '../Firebase2/Storage2/dbCheckResults';
import { getControlGroupListByStructId } from '../Firebase2/Storage2/dbControlGroup';

import { getInterventionById } from '../Firebase2/Storage2/dbIntervention'





const recalc_param_list = [
	'status',
	'size',
	'risk_area',
	'status_planning',
	'cdc',
	'roomtype'
]

/* 
const roomtype_params = [
	'risk_area',
	'floor',
	//'floor_status',
	'furniture_list',
	'economic_material_list',
	'environment_element_list',
	'need_waxing',
] */


function ModalRoomItem({ is_new, item, setItem, t, hover, parent, setParent, structureId, ui_list, structureElements, canWrite }) {
	const { domain } = useContext(DomainContext)
	//console.log("DOMAIN---", domain)
	const { contract, roomtype_dict } = useContext(GlobalDataContext)
	const edit = useContext(EditContext)

	const childRefAlert = useRef()

	const [uData, setUData] = useState([])
	const editable = is_new || isEditable(contract.status)
	const contractId = contract.id
	let new_pl = []
	if (parent.parent_list) {
		new_pl = [...parent.parent_list]

	}
	const newitem = {
		name: '',
		creationDate: firebase.firestore.Timestamp.now(),
		mq: 0,
		size: 0,
		note: '',
		roomtype: {},
		risk_area_summary: [],
		type_name: "",
		need_waxing: false,
		glass_percentage: 0,
		is_decommissioned: false,
		level: 4,
		end_item: true,
		risk_area: {},
		environment_element_list: [],
		floor: {},
		status: 'active',
		floor_status: {},
		parent_list: new_pl,
		furniture_list: [],
		washable_walls: false,
		countertop: false,
		countertop_percentage: 0,
		wall_material: 'polish',
		washable_walls_size: 0,
		cost_center: {},
		economic_material_list: [],
		check_list: [],
		status_planning: [],
		//next_update: firebase.firestore.Timestamp.now(),
		planning_event_list: []

	}
	const [log_list, setLogList] = useState()
	const [check_dict, setCheckDict] = useState()



	const [showItemEdit, setShowItemEdit] = useState(false)
	const [curItem, setCurItem] = useState(newitem)
	const [isSaving, setIsSaving] = useState(false)
	const [editedElement, setEditedElement] = useState(false)
	const [multipleEdit, setMultipleEdit] = useState(false)
	const [validationError, setValidationError] = useState()
	const [message_list, setMessageList] = useState([])
	let needRecalc = false
	const [pre_planning_length, setPreplanningLength] = useState(0)

	const roomtype_params_dict = roomTypeParams_dict({ t: t, contract_type: contract.type })
	const handleShowEditItem = async (i) => {
		setIsSaving(false)
		if (edit.editMultiple && edit.editList.length > 0) {
			edit.setEditParamList([])
			if (item.planning_event_list) {
				setPreplanningLength(item.planning_event_list.length)
			}
			setCurItem(edit.editList[0])
			setMultipleEdit(true)
		} else {
			if (item.not_conformed) {
				setCurItem(item)

			} else {
				const rt = roomtype_dict[item.roomtype.id]
				setCurItem({
					...item,
					'risk_area': rt.risk_area,
					'floor': rt.floor,
					'furniture_list': rt.furniture_list,
					'need_waxing': rt.need_waxing || false,
					'economic_material_list': rt.economic_material_list,
					'environment_element_list': rt.environment_element_list,
				})
			}
		}
		setShowItemEdit(true)
		setValidationError()
		setEditedElement(false)
		const res = await getInterventionLogListRoom(domain, contract.id, item.id, 1)
		setLogList(res)
		const g_id = await getControlGroupListByStructId(domain, contractId, item.parent_list[0])
		console.log(g_id)
		if (g_id && g_id[0]) {
			const res_res = await getLastCheckResultByRoom(domain, contractId, g_id[0].id, 'contradictory_results', item.id)
			const res_proc = await getLastCheckResultByRoom(domain, contractId, g_id[0].id, 'contradictory_process', item.id)
			console.log(res_res, res_proc)
			setCheckDict({ results: res_res, process: res_proc })
		}
		console.log("return res", res)
	}

	const handleShowNewItem = () => {
		setEditedElement(false)
		setIsSaving(false)
		newitem.level = parent.level + 1
		setCurItem(newitem)
		setShowItemEdit(true)
	}

	const handleCloseItem = () => {
		setEditedElement(false)
		setShowItemEdit(false)
	}




	const checkValidName = () => {
		try {
			const v_name = parent.childs.filter(r => r.id !== curItem.id && r.name.toString() === curItem.name).length
			if (v_name === 0) {
				return true
			} else {
				return false
			}
		} catch (err) {
			console.log(err)
			return false
		}
	}


	const handleSaveItem = async () => {

		if (checkValidName()) {
			setIsSaving(true)
			if (is_new) {
				curItem.parent_list.push(parent.id)
				console.log(contractId, structureId, parent.parent_list[1], parent.parent_list[2], parent.id, curItem)
				const return_data = addRoom2(domain, contractId, parent.parent_list[0], parent.parent_list[1], parent.parent_list[2], parent.id, curItem)
				if (return_data.error) {

				} else {
					console.log('addRoom', return_data)
					let p_childs = [...parent.childs]
					p_childs.push(curItem)
					console.log('level', domain, parent.level, curItem.level)
					//setItem({ ...parent, 'childs': mylist })
					setParent({ ...parent, 'childs': p_childs })
					processRoomsStatus(domain, contractId, parent.parent_list[0])
				}
			} else {
				if (edit.editMultiple && edit.editParamList.length !== 0) {

					console.log(edit.editParamList)
					let parent_edit_list = { 'pavillon': [], 'floor': [], 'department': [], }
					let department_childs = {}
					for (let r of edit.editList) {
						console.log("originalroom", editable, r)
						if (editable) {
							for (const p of edit.editParamList) {
								console.log(p, curItem[p])
								r[p] = curItem[p]
							}
						} else {
							//for (const p of edit.editParamList.filter(p => recalc_param_list.indexOf(p) !== -1)){
							for (const p of edit.editParamList) {
								console.log(p, curItem[p])
								r[p] = curItem[p]
							}
							if (curItem.planning_event_list.length > pre_planning_length) {

								let editplanning = []
								console.log("PLANNING", r.planning_event_list)
								if (r.planning_event_list) {
									editplanning = [...r.planning_event_list]
								}
								const new_elem_count = curItem.planning_event_list.length - pre_planning_length
								const t_list = curItem.planning_event_list.slice(-new_elem_count)
								console.log("planned", new_elem_count, t_list)
								r.planning_event_list = [...editplanning, ...t_list]
								r.next_update = curItem.next_update
							}
						}



						if (parent_edit_list.pavillon.indexOf(r.parent_list[1]) === -1) {
							parent_edit_list.pavillon.push(r.parent_list[1])
						}
						if (parent_edit_list.floor.indexOf(r.parent_list[2]) === -1) {
							parent_edit_list.floor.push(r.parent_list[2])
						}
						if (parent_edit_list.department.indexOf(r.parent_list[3]) === -1) {
							parent_edit_list.department.push(r.parent_list[3])
							department_childs[r.parent_list[3]] = [r]
						} else {
							department_childs[r.parent_list[3]].push(r)
						}
						const return_data = editRoom2(domain, contractId, r.parent_list[0], r.parent_list[1], r.parent_list[2], r.parent_list[3], r.id, r)
						console.log("editedroom", r, return_data)
					}
					if (needRecalc || curItem.needRecalc) {
						console.log("NEED RECALC parent_edit_list", parent_edit_list, department_childs, edit.editParentFunctions)
						delete curItem.needRecalc
						parent_edit_list.department.map(d => {
							let p_childs = [...edit.editParentFunctions[d].parent.childs]
							department_childs[d].map(r => {
								const c = checkComponentPresence(r, p_childs, 'id')
								p_childs.splice(c, 1, r)

							})
							edit.editParentFunctions[d].edit({ ...edit.editParentFunctions[d].parent, childs: p_childs })
						})
						const r_data = await reconcileRooms(domain, contractId, edit.editList[0].parent_list[0])
						console.log("RECONCILE", r_data)
						if (r_data.error) {

						} else {
							console.log("process room status")
							await processRoomsStatus(domain, contractId, edit.editList[0].parent_list[0])
						}


					}



				} else {
					console.log("(edit singole) room structure", domain, contractId, item.parent_list[0], item.parent_list[1], item.parent_list[2], item.parent_list[3], curItem.id, curItem)
					const return_data = await editRoom2(domain, contractId, item.parent_list[0], item.parent_list[1], item.parent_list[2], item.parent_list[3], curItem.id, curItem)
					console.log(return_data)
					let p_childs = [...parent.childs]
					console.log(curItem.id, parent.childs)
					const c = checkComponentPresence(curItem, parent.childs, 'id')
					await processRoomsStatus(domain, contractId, item.parent_list[0])
					const saved_item = await getRoomById(domain, contractId, item.parent_list[0], item.parent_list[1], item.parent_list[2], item.parent_list[3], curItem.id)
					console.log("RETURN SAVED ROOM", saved_item.status, saved_item)
					setItem(saved_item)
					p_childs.splice(c, 1, saved_item)
					if (needRecalc || curItem.needRecalc) {
						console.log("SAVE NEED RECALC")
						delete curItem.needRecalc
						setParent({ ...parent, 'childs': p_childs })
					}
				}
			}
			setIsSaving(false)
			setShowItemEdit(false)
		} else {
			childRefAlert.current.addAlert('danger', 'numero locale già presente in questo reparto')
		}
	}



	const onChangeHandler = (el) => {
		let { name, value, type } = el
		let sub = undefined
		try {
			sub = el.getAttribute('sub')
		} catch (err) {
			sub = undefined;
		}
		console.log(name, value, type, sub)
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = curItem[name]
			if (el.checked) {
				oldcheck[value] = value;
			} else {
				oldcheck.splice(value, 1);
			}
			value = oldcheck
		}
		if (sub) {
			value = { ...curItem[sub], [name]: value }
			name = sub;
		}
		console.log(name, value)
		if (name === 'roomtype') {
			//			const rt = getSelectedElement(ui_list.roomtype_list, value, 'id')
			const rt = roomtype_dict[value.id]
			console.log('================change room type -> load template', rt)
			if (value !== '-1' && rt) {
				setCurItem({
					...curItem, 'roomtype': { id: value.id, name: rt.name },
					'risk_area': value.risk_area,
					'floor': value.floor,
					//					'floor_status': value.floor_status,
					'furniture_list': value.furniture_list,
					'need_waxing': value.need_waxing || false,
					'economic_material_list': value.economic_material_list,
					'environment_element_list': value.environment_element_list,
					'not_conformed': false,
				})
			} else {
				setCurItem({ ...curItem, [name]: value })

			}
			if (edit.editMultiple) {
				console.log("Multiple param list", [...edit.editParamList, ...Object.keys(roomtype_params_dict)])
				edit.setEditParamList([...edit.editParamList, ...Object.keys(roomtype_params_dict)])
			}
		} else {
			if (name === 'name') {
				if (validateName(value)) {
					setCurItem({ ...curItem, [name]: value })
				} else {
					childRefAlert.current.addAlert('danger', `numero locale (${value}) già presente in questo reparto`)
					return
				}
			} else {
				if (roomtype_params_dict[name]) {
					setCurItem({ ...curItem, [name]: value, not_conformed: true })
				} else {
					setCurItem({ ...curItem, [name]: value })
				}

			}

		}
		if (recalc_param_list.indexOf(name) !== -1) {
			console.log("PARAM need recalc")
			needRecalc = true
		}
		setEditedElement(true)
	}


	const validateName = (new_name) => {

		if (parent.childs) {
			const same_name = parent.childs.filter(r => r.name.toString().toLowerCase() === String(new_name).toLowerCase()).length
			if (same_name > 0) {
				return false
			} else {
				return true
			}
		} else {
			return true
		}

	}

	useEffect(() => {
		try {
			setUData(JSON.parse(localStorage.getItem('userData')))
		} catch (err) {

		}

	}, [])






	const renderMultiRoomPopover = () => {

		const getRoomtypeData = (rt) => {
			try {
				return <> - {ui_list.roomtype_dict[rt].name}</>
			} catch (err) {
				return ''
			}
		}

		return (
			<Popover id="popover-basic" className="bg-info border border-info">
				<Popover.Content>
					{edit.editList.length > 0 ?
						<ListGroup variant="flush" className="d-inline-block">
							{edit.editList.map((r, k) => (
								<ListGroup.Item key={k} className={"p-2 border-left w-50 " + (k % 2 === 0 ? 'float-left' : 'float-right')}>
									{r.name} {getRoomtypeData(r.roomtype.id)}
								</ListGroup.Item>
							))}
						</ListGroup>
						: ''
					}
				</Popover.Content>
			</Popover>
		)
	}



	return (
		<>


			<Modal show={showItemEdit} onHide={handleCloseItem} className="bg-secondary" dialogClassName="custom-modal" backdrop="static" scrollable={true}>
				<AlertSection ref={childRefAlert} />
				<Modal.Header className={editedElement ? "bg-warning" : ""}>
					{is_new ? <Modal.Title><span className="text-primary"><FontAwesomeIcon icon="plus-square" />{t('structure.labels.new_room')} </span> <PathName parent_list={parent.parent_list} parent_id={parent.id} /> | locale {curItem.name} {editedElement ? <FontAwesomeIcon icon="save" /> : ''}</Modal.Title>
						:
						<Modal.Title>
							{multipleEdit ?
								<>
									<span className="text-info"><FontAwesomeIcon icon="pencil-alt" />
										{t('structure.labels.edit_multipleroom')} </span>

									<OverlayTrigger trigger={['focus', 'hover']} placement="right-start" overlay={renderMultiRoomPopover()}>
										<span className="small">({edit.editList.length} {t('structure.labels.rooms')})</span>
									</OverlayTrigger>
								</>
								: <>
									<span className="text-info"><FontAwesomeIcon icon="pencil-alt" />
										{t('structure.labels.edit_room')}</span> <PathName parent_list={parent.parent_list} parent_id={parent.id}/> | locale {curItem.name}
								</>}
							{editedElement ? <FontAwesomeIcon icon="save" /> : ''}
						</Modal.Title>
					}
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<Tab.Container id="modal-tab-navigator" defaultActiveKey="tabDetails">
							<Row>
								<Col sm={2}>
									<Nav variant="pills" className="flex-column">
										<Nav.Item className="mb-1">
											<Nav.Link variant="secondary" eventKey="tabDetails">{t('global.labels.details')} </Nav.Link>
										</Nav.Item>
										<Nav.Item className="mb-1">
											<Nav.Link variant="secondary" eventKey="tabComposition">{t('global.labels.composition')}</Nav.Link>
										</Nav.Item>
										<Nav.Item className="mb-1">
											<Nav.Link variant="secondary" eventKey="tabIntervention">{t('global.labels.interventions')}</Nav.Link>
										</Nav.Item>
										{!edit.editMultiple ?
											<Nav.Item className="mb-1">
												<Nav.Link variant="secondary" eventKey="tabMedia" disabled={is_new}>{t('structure.labels.imagesdocs')} </Nav.Link>
											</Nav.Item>
											: ''}
									</Nav>
								</Col>
								<Col>
									<Tab.Content>
										<Tab.Pane eventKey="tabDetails">
											<ElementFormRoomDetails element={curItem} t={t} functions={{ edit: onChangeHandler, setItem: setCurItem, setEdit: setEditedElement }} ui_list={ui_list} canWrite={canWrite} is_new={is_new} />
										</Tab.Pane>
										<Tab.Pane eventKey="tabComposition">
											<ElementFormComposition element={curItem} t={t} functions={{ edit: onChangeHandler, setItem: setCurItem, setEdit: setEditedElement }} canWrite={canWrite} is_new={is_new} is_operation={false} />
										</Tab.Pane>
										<Tab.Pane eventKey="tabIntervention">
											<ElementFormFrequencySummary ui_list={ui_list} element={curItem} t={t} functions={{ edit: onChangeHandler, setItem: setCurItem, setEdit: setEditedElement }} canWrite={canWrite} is_new={is_new} is_operation={false} log_list={log_list} check_dict={check_dict} />
										</Tab.Pane>
										<Tab.Pane eventKey="tabMedia">
											<ElementFormMedia element={curItem} t={t} ui_list={ui_list} functions={{ edit: onChangeHandler, save: handleSaveItem }} section='room' canWrite={canWrite} />
										</Tab.Pane>
									</Tab.Content>
								</Col>
							</Row>
						</Tab.Container>
					</Container>
				</Modal.Body>
				<Modal.Footer>
					{!is_new && !multipleEdit ?
						<ModalRoomPreview element={curItem} t={t} />
						: ''}
					<Button disabled={isSaving} variant="secondary" onClick={handleCloseItem}>
						{t('global.labels.close')}
					</Button>
					{canWrite ?
						<Button className={editedElement ? "btn-warning" : "btn-myprimary"} form="companyForm" onClick={handleSaveItem}>
							{isSaving ?
								<Spinner size="sm" animation="border" role="status" />
								: <FontAwesomeIcon icon="save" />
							}
							&nbsp;
							{t('global.labels.save')}
						</Button>
						: ''}
				</Modal.Footer>
			</Modal>




			<ButtonGroup>

				{is_new
					? <Button variant="outline-primary" size="sm" onClick={() => handleShowNewItem()}>
						<OverlayTrigger
							placement="auto"
							delay={{ show: 250, hide: 100 }}
							overlay={
								<Tooltip >
									{t('structure.popover.add_room')}
								</Tooltip>}
						>
							<FontAwesomeIcon icon="plus-square" />
						</OverlayTrigger>
					</Button>
					: (hover
						?
						[edit.editMultiple
							?
							<Button className="mr-1" variant="outline-info" size="sm" onClick={() => handleShowEditItem()}>
								<OverlayTrigger
									placement="auto"
									delay={{ show: 250, hide: 100 }}
									overlay={
										<Tooltip  >
											{t('structure.popover.edit_rooms')}
										</Tooltip>}
								>
									<FontAwesomeIcon icon="pencil-alt" />
								</OverlayTrigger>
							</Button>
							:
							<>
								{uData.isAdmin || (uData.sendmessage && uData.sendmessage.length > 0) ?
									<DropdownButton as={ButtonGroup} title="Messaggi" id="bg-nested-dropdown" size="sm" variant="outline-primary">
										{console.log("CUR ITEM", item)}
										{uData.isAdmin || (uData.sendmessage && uData.sendmessage.indexOf('request') >= 0) ?
											<ModalTicket is_new={true} t={t} item={message_list} setItem={setMessageList} ui_list={ui_list} item_list={message_list} type='request' parent_list={[...item.parent_list, item]} structureElements={structureElements} is_menu={1} />
											: ''}
										{uData.isAdmin || (uData.sendmessage && uData.sendmessage.indexOf('communication') >= 0) ?
											<ModalTicket is_new={true} t={t} item={message_list} setItem={setMessageList} ui_list={ui_list} item_list={message_list} type='communication' parent_list={[...item.parent_list, item]} structureElements={structureElements} is_menu={2} />
											: ''}
										{uData.isAdmin || (uData.sendmessage && uData.sendmessage.indexOf('noncompliance') >= 0) ?
											<ModalTicket is_new={true} t={t} item={message_list} setItem={setMessageList} ui_list={ui_list} item_list={message_list} type='noncompliance' parent_list={[...item.parent_list, item]} structureElements={structureElements} is_menu={3} />
											: ''}
										{uData.isAdmin || (uData.sendmessage && uData.sendmessage.indexOf('failure') >= 0) ?
											<ModalTicket is_new={true} t={t} item={message_list} setItem={setMessageList} ui_list={ui_list} item_list={message_list} type='failure' parent_list={[...item.parent_list, item]} structureElements={structureElements} is_menu={4} />
											: ''}
										{uData.isAdmin || (uData.sendmessage && uData.sendmessage.indexOf('registry_change') >= 0) ?
											<ModalTicket is_new={true} t={t} item={message_list} setItem={setMessageList} ui_list={ui_list} item_list={message_list} type='registry_change' parent_list={[...item.parent_list, item]} structureElements={structureElements} is_menu={5} />
											: ''}
									</DropdownButton>
									: ''}
								<Button className="mr-1" variant="outline-info" size="sm" onClick={() => handleShowEditItem()}>
									<OverlayTrigger
										placement="auto"
										delay={{ show: 250, hide: 100 }}
										overlay={
											<Tooltip  >
												{t('structure.popover.edit_room')}
											</Tooltip>}
									>
										<FontAwesomeIcon icon="pencil-alt" />
									</OverlayTrigger>
								</Button>
							</>
						]
						: '')
				}
			</ButtonGroup>

		</>
	)
}


////////////////////////////////////////////////////////////////////////////////////////////////////////
function ElementFormRoomDetails({ element, functions, t, ui_list, canWrite, is_new }) {

	const now = new Date()
	const { contract, } = useContext(GlobalDataContext)
	const editable = is_new || isEditable(contract.status)
	const { locale } = useContext(LocaleContext)
	const edit = useContext(EditContext)
	const getGlassMq = (totalsize, glass) => {

		let g_perc = 0

		try {
			g_perc = (getFloatNumber(totalsize) / 100 * getFloatNumber(glass)).toFixed(1)
		} catch (err) {

		}
		//console.log("GLASS", g_perc, totalsize, glass)
		if (isNaN(g_perc)) {
			g_perc = 0
		}
		return (<>
			{g_perc} m<sup>2</sup>
		</>
		)
	}

	const status_dict = roomStatus_dict({ t })

	const wall_material_dict = wallMaterials_dict({ t })


	const status_list = Object.values(status_dict)



	return (
		<>
			{edit.editMultiple ?
				<Row>
					<Col className="bg-secondary text-light">
						{t('structure.labels.modified_params')}
					</Col>

				</Row>
				: ''
			}
			{!edit.editMultiple ?
				<Form.Group as={Row} controlId="formStructureCode" className="border-bottom">
					<Form.Label column sm="2">{t('global.labels.number')}</Form.Label>
					<Col sm="8">
						<Form.Control className="mb-2" type="text" defaultValue={element.name} name="name" onChange={(event) => functions.edit(event.currentTarget)} />
					</Col>
				</Form.Group>
				: ''}
			<Form.Group as={Row} controlId="formStructureStructure" className="border-bottom">
				{edit.editMultiple ?
					<MultipleToggleEdit param={'roomtype'} />
					: ''}
				<Form.Label column sm="2">{t('structure.labels.roomtype')} </Form.Label>
				<Col sm="8">
					<Form.Control as="select" className="mb-2" value={element.roomtype.id} name="roomtype" disabled={edit.editMultiple && edit.editParamList.indexOf('roomtype') === -1}
						onChange={(event) => functions.edit({ name: 'roomtype', type: 'select', value: getSelectedElement(event.target.value, ui_list.roomtype_list, 'id') })} >
						<option value="-1">{t('structure.labels.select_roomtype')}</option>
						{ui_list.roomtype_list ?
							(ui_list.roomtype_list.map((i, k) => (
								<option key={k} value={i.id}>{i.name} ({i.risk_area && i.risk_area.Id ? i.risk_area.name[locale] : '-'})</option>
							)))
							: ''
						}
					</Form.Control>
				</Col>
				{element.not_conformed ?
					<Col>
						<OverlayTrigger
							placement="auto"
							delay={{ show: 250, hide: 150 }}
							overlay=
							{<Tooltip id="button-tooltip">locale modificato rispetto alla tipologia associata</Tooltip>}
						><Badge variant="warning" size="md" className="mr-1">
								<FontAwesomeIcon icon="exclamation-triangle" />
							</Badge>
						</OverlayTrigger>
					</Col>
					: <></>}
			</Form.Group>
			<Form.Group as={Row} controlId="formStructureStructure" className="border-bottom">
				{edit.editMultiple && editable ?
					<MultipleToggleEdit param={'risk_area'} />
					: ''}
				<Form.Label column sm="2">{t('structure.labels.risk_area')} </Form.Label>
				{editable ?
					<>
						<Col sm="8">
							<Form.Control as="select" className="mb-2" value={element.risk_area.Id} disabled={edit.editMultiple && edit.editParamList.indexOf('risk_area') === -1} name="risk_area" onChange={(event) => functions.edit({ name: 'risk_area', type: 'select', value: getSelectedElement(event.target.value, ui_list.risk_area_list) })} >
								<option value="-1">{t('structure.labels.select_risk_area')}</option>
								{ui_list.risk_area_list ?
									(ui_list.risk_area_list.map((i, k) => (
										<option key={k} value={i.Id}>{i.name[locale]}</option>
									))) : ''}
							</Form.Control>
						</Col>
						<Col>
							{element.risk_area.color ?
								<Badge className="d-flex" style={{
									background: rgba2hex(ui_list.risk_area_dict[element.risk_area.Id].color)
								}}>&nbsp;</Badge>
								: ''}
						</Col>
					</>
					:
					[element.risk_area.Id ?
						<>
							<Col sm="1">
								{getBaseObjectValue(ui_list.risk_area_dict[element.risk_area.Id], 'name', locale)}
							</Col>
							<Col sm="1">
								<Badge className="d-flex" style={{
									background: rgba2hex(ui_list.risk_area_dict[element.risk_area.Id].color)
								}}>&nbsp;</Badge>
							</Col>
							<ElementFormParamHistory element={element} functions={functions} ui_list={ui_list} t={t} param_list={ui_list.risk_area_list} canWrite={canWrite} label={
								contract.type === 'civil' ?
									t('global.labels.standard_cleaning')
									:
									t('global.labels.risk_area')

							} param={'risk_area'} base_object={true} id_value={'Id'} />
						</>
						: ''
					]
				}
			</Form.Group>
			{!edit.editMultiple && contract.risk_area_amount[element.risk_area.Id] ?
				<Row className="border-bottom mb-1 pb-1">
					<Col sm="2">
						{t('structure.labels.contract_amount')}
					</Col>
					<Col>
						<Row>
							<Col className="bg-secondary text-light">
								{t('structure.labels.value')} m<sup>2</sup>
							</Col>
							<Col className="bg-secondary text-light">
								{t('structure.labels.dayvalue')}
							</Col>
							<Col className="bg-secondary text-light">
								{t('structure.labels.monthvalue')}
							</Col>
						</Row>
						<Row>
							<Col>
								{contract.risk_area_amount[element.risk_area.Id]} <span className="text-secondary">€ </span>
							</Col>
							<Col>
								{(contract.risk_area_amount[element.risk_area.Id] * element.size / 30).toFixed(2)} <span className="text-secondary">€ </span>
							</Col>
							<Col>
								{(contract.risk_area_amount[element.risk_area.Id] * element.size).toFixed(2)} <span className="text-secondary">€</span>
							</Col>

						</Row>
					</Col>
				</Row>
				: ''}

			<Form.Group as={Row} controlId="formStructureStructure" className="border-bottom">
				{edit.editMultiple ?
					<MultipleToggleEdit param={'floor'} />
					: ''}
				<Form.Label column sm="2">{t('global.labels.flooring')} </Form.Label>
				<Col sm="4">
					<Form.Control as="select" className="mb-2" value={element.floor.Id} disabled={edit.editMultiple && edit.editParamList.indexOf('floor') === -1} name="floor" onChange={(event) => functions.edit({ name: 'floor', type: 'select', value: getSelectedElement(event.target.value, ui_list.floor_list) })} >
						<option value="-1" >{t('roomtype.labels.select_floor')}</option>
						{ui_list.floor_list
							? [(ui_list.floor_list).map((f, k) => (
								<option key={k} value={f.Id} >{f.name[locale]}</option>
							))]
							: ''
						}
					</Form.Control>
					<Form.Text className="text-muted">
						{t('roomtype.labels.floor_type')}
					</Form.Text>
				</Col>
				{edit.editMultiple ?
					<MultipleToggleEdit param={'floor_status'} />
					: ''}
				<Col sm="4">
					<Form.Control as="select" className="mb-2" value={element.floor_status.Id} disabled={edit.editMultiple && edit.editParamList.indexOf('floor_status') === -1} name="floor_status" onChange={(event) => functions.edit({ name: 'floor_status', type: 'select', value: getSelectedElement(event.target.value, ui_list.floor_status_list) })} >
						<option value="-1" >{t('roomtype.labels.select_floorstatus')}</option>
						{ui_list.floor_status_list
							? [Object(ui_list.floor_status_list).map((f, k) => (
								<option key={k} value={f.Id} >{f.name[locale]}</option>
							))]
							: ''
						}
					</Form.Control>
					<Form.Text className="text-muted">
						{t('roomtype.labels.floor_status')}
					</Form.Text>
				</Col>
			</Form.Group>
			<Form.Group as={Row} className="border-bottom">
				{edit.editMultiple ?
					<MultipleToggleEdit param={'can_mechanized_washing'} />
					: ''}
				<Form.Label column sm="2">{t('structure.labels.can_mechanized_washing')}</Form.Label>
				<Col sm="8">
					<Form.Check type="radio" name="can_mechanized_washing" value={true} disabled={edit.editMultiple && edit.editParamList.indexOf('can_mechanized_washing') === -1} checked={element.can_mechanized_washing} label={t('global.labels.yes')} onChange={(event) => functions.edit(event.currentTarget)} />
					<Form.Check type="radio" name="can_mechanized_washing" value={false} disabled={edit.editMultiple && edit.editParamList.indexOf('can_mechanized_washing') === -1} checked={!element.can_mechanized_washing} label={t('global.labels.no')} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} className="border-bottom">
				{edit.editMultiple ?
					<MultipleToggleEdit param={'need_waxing'} />
					: ''}
				<Form.Label column sm="2">{t('structure.labels.need_waxing')}</Form.Label>
				<Col sm="8">
					<Form.Check type="radio" name="need_waxing" value={true} disabled={edit.editMultiple && edit.editParamList.indexOf('need_waxing') === -1} checked={element.need_waxing} label={t('global.labels.yes')} onChange={(event) => functions.edit(event.currentTarget)} />
					<Form.Check type="radio" name="need_waxing" value={false} disabled={edit.editMultiple && edit.editParamList.indexOf('need_waxing') === -1} checked={!element.need_waxing} label={t('global.labels.no')} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} className="border-bottom">
				{edit.editMultiple && editable ?
					<MultipleToggleEdit param={'size'} />
					: ''}
				<Form.Label column sm="2">{t('structure.labels.size')}</Form.Label>
				{editable ?
					<>
						<Col sm="8">
							<InputGroup className="mb-2">
								<FormControl type="number" min="0" step="0.01" disabled={edit.editMultiple && edit.editParamList.indexOf('size') === -1} value={element.size} name="size" onChange={(event) => functions.edit(event.currentTarget)} />
								<InputGroup.Append>
									<InputGroup.Text id="basic-addon1"> m<sup>2</sup> </InputGroup.Text>
								</InputGroup.Append>
							</InputGroup>
						</Col>
					</>
					:
					<>
						<Col sm="2">
							{element.size} m<sup>2</sup>
						</Col>
						<ElementFormParamHistory element={element} functions={functions} ui_list={ui_list} t={t} canWrite={canWrite} label={t('structure.labels.size')} param={'size'} />
					</>
				}
			</Form.Group>
			<Form.Group as={Row} className="border-bottom">
				{edit.editMultiple ?
					<MultipleToggleEdit param={'washable_walls'} />
					: ''}
				<Form.Label column sm="2">{t('structure.labels.washable_walls')}</Form.Label>
				<Col sm="2">
					<Form.Check type="radio" name="washable_walls" value={true} disabled={edit.editMultiple && edit.editParamList.indexOf('washable_walls') === -1} checked={element.washable_walls} label={t('global.labels.yes')} onChange={(event) => functions.edit(event.currentTarget)} />
					<Form.Check type="radio" name="washable_walls" value={false} disabled={edit.editMultiple && edit.editParamList.indexOf('washable_walls') === -1} checked={!element.washable_walls} label={t('global.labels.no')} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
				{element.washable_walls ?
					<>
						<Col sm="4">
							{edit.editMultiple ?
								<MultipleToggleEdit param={'wall_material'} />
								: ''}
							<Form.Control as="select" className="mb-2" value={element.wall_material} disabled={edit.editMultiple && edit.editParamList.indexOf('wall_material') === -1} name="wall_material" onChange={(event) => functions.edit({ name: 'wall_material', type: 'select', value: event.target.value })} >
								{Object.keys(wall_material_dict).map((f, k) => (
									<option key={k} value={f} >{wall_material_dict[f].label}</option>
								))}
							</Form.Control>
							<Form.Text className="text-muted">
								{t('structure.labels.wall_material')}
							</Form.Text>
						</Col>
						<Col>
							{edit.editMultiple ?
								<MultipleToggleEdit param={'washable_walls_size'} />
								: ''}
							<InputGroup className="mb-2">
								<Form.Control type="number" min="0" step="0.01" name="washable_walls_size" value={element.washable_walls_size} disabled={edit.editMultiple && edit.editParamList.indexOf('washable_walls_size') === -1} onChange={(event) => functions.edit(event.currentTarget)} />
								<InputGroup.Append>
									<InputGroup.Text id="basic-addon1"> m<sup>2</sup> </InputGroup.Text>
								</InputGroup.Append>
							</InputGroup>
							<Form.Text className="text-muted">
								{t('structure.labels.washable_walls_size')}
							</Form.Text>
						</Col>
					</>
					: ''}
			</Form.Group>
			<Form.Group as={Row} className="border-bottom">
				{edit.editMultiple ?
					<MultipleToggleEdit param={'countertop'} />
					: ''}
				<Form.Label column sm="2">{t('structure.labels.countertop')}</Form.Label>
				<Col sm="4">
					<Form.Check type="radio" name="countertop" value={true} disabled={edit.editMultiple && edit.editParamList.indexOf('countertop') === -1} checked={element.countertop} label={t('global.labels.yes')} onChange={(event) => functions.edit(event.currentTarget)} />
					<Form.Check type="radio" name="countertop" value={false} disabled={edit.editMultiple && edit.editParamList.indexOf('countertop') === -1} checked={!element.countertop} label={t('global.labels.no')} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
				{element.countertop ?
					<>
						<Col sm="4">
							<InputGroup className="mb-2">
								<Form.Control type="number" min="0" max="100" step="0.01" name="countertop_percentage" value={element.countertop_percentage} disabled={edit.editMultiple && edit.editParamList.indexOf('countertop_percentage') === -1} onChange={(event) => functions.edit(event.currentTarget)} />
								<InputGroup.Append>
									<InputGroup.Text >%</InputGroup.Text>
								</InputGroup.Append>
							</InputGroup>
							<Form.Text className="text-muted">
								{t('structure.labels.countertop_percentage')}
							</Form.Text>
						</Col>
						<Col sm="2">
							{getGlassMq(element.size, element.countertop_percentage)}
						</Col>
					</>
					: ''}
			</Form.Group>
			<Form.Group as={Row} className="border-bottom">
				{false && edit.editMultiple ?
					<MultipleToggleEdit param={'glass_percentage'} />
					: ''}
				<Form.Label column sm="2">{t('structure.labels.glass_percentage')}</Form.Label>
				{true || editable ?
					<>
						<Col sm="8">
							<InputGroup className="mb-2">
								<FormControl type="number" min="0" step="0.01" max="100" disabled={edit.editMultiple && edit.editParamList.indexOf('glass_percentage') === -1} value={element.glass_percentage} name="glass_percentage" onChange={(event) => functions.edit(event.currentTarget)} />
								<InputGroup.Append>
									<InputGroup.Text >%</InputGroup.Text>
								</InputGroup.Append>
							</InputGroup>
						</Col>
						<Col sm="2">
							{getGlassMq(element.size, element.glass_percentage)}
						</Col>
					</>
					:
					<>
						<Col sm="1">
							{element.glass_percentage} %
						</Col>
						<Col sm="1">
							{getGlassMq(element.size, element.glass_percentage)}
						</Col>
						<ElementFormParamHistory element={element} functions={functions} ui_list={ui_list} t={t} canWrite={canWrite} label={t('structure.labels.glass_percentage')} param={'glass_percentage'} />
					</>
				}
			</Form.Group>
			<Form.Group as={Row} className="border-bottom">
				{edit.editMultiple && editable ?
					<MultipleToggleEdit param={'cdc'} />
					: ''}
				<Form.Label column sm="2">{t('structure.labels.cost_center')}</Form.Label>
				{editable ?
					<>
						<Col sm="8">
							<Form.Control className="mb-2" type="text" value={element.cdc} disabled={edit.editMultiple && edit.editParamList.indexOf('cdc') === -1} name="cdc" onChange={(event) => functions.edit(event.currentTarget)} />
						</Col>
					</>
					:
					<>
						<Col sm="2">
							{element.cdc}
						</Col>
						<ElementFormParamHistory element={element} functions={functions} ui_list={ui_list} t={t} canWrite={canWrite} label={t('structure.labels.cdc')} param={'cdc'} />
					</>
				}
			</Form.Group>
			<Form.Group as={Row} controlId="formStructureStatus" className="border-bottom">
				{edit.editMultiple && editable ?
					<MultipleToggleEdit param={'status'} />
					: ''}
				<Form.Label column sm="2">{t('global.labels.status')}</Form.Label>
				{editable ?
					<>
						<Col>
							{Object.keys(status_dict).map((s, k) => (
								<Form.Check key={k} type="radio" name="status" value={s} disabled={edit.editMultiple && edit.editParamList.indexOf('status') === -1} label={status_dict[s].label_status} checked={element.status === s} onChange={(event) => functions.edit(event.currentTarget)} />
							))}
						</Col>
					</>
					:
					<>
						<Col sm="2">
							{getValue(status_dict[element.status], 'label_status')}
						</Col>
						{/* 						<ElementFormStatus element={element} functions={functions} ui_list={ui_list} t={t} status_list={status_list} canWrite={canWrite} /> */}
						<ElementFormParamHistory element={element} functions={functions} ui_list={ui_list} t={t} canWrite={canWrite} param_list={status_list} label={t('global.labels.status')} param={'status'} base_object={false} />
					</>
				}

			</Form.Group>
			<Form.Group as={Row} className="border-bottom">
				{edit.editMultiple ?
					<MultipleToggleEdit param={'note'} />
					: ''}
				<Form.Label column sm="2">{t('structure.labels.note')}</Form.Label>
				<Col sm="8">
					<Form.Control className="mb-2" type="text" name="note" value={element.note} disabled={edit.editMultiple && edit.editParamList.indexOf('note') === -1} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			{/* 			<Form.Group as={Row} controlId="formStructureCF" className="border-bottom">
				<Form.Label column sm="2">{t('global.labels.not_standard')}</Form.Label>
				<Col>
					<Form.Check type="radio" name="not_conformed" value={false} checked={(element && element.not_conformed === false || element.not_conformed=== undefined) || true} label={t('global.labels.yes')} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
				<Col>
					<Form.Check type="radio" name="not_conformed" value={true} checked={(element && element.not_conformed === true) || false} label={t('global.labels.no')} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group> */}
			{console.log("UPDATE", element.next_update && myTimestampToDate(element.next_update) > now)}
			{
				element.next_update && myTimestampToDate(element.next_update) > now ?
					<Row>
						<Col className="text-muted">
							{t('global.messages.next_update')}: {format(myTimestampToDate(element.next_update), 'dd/MM/yyyy')}
						</Col>
					</Row>
					: ''
			}
			<Row>
				<Col>
					{!is_new ?
					<>
						<QRCode value={
							`http://clauditpublic.web.app/rooms/${element.id}`
						} />
						<Button variant="outline-primary" size="sm" onClick={() => window.open(`http://clauditpublic.web.app/rooms/${element.id}`)}><FontAwesomeIcon icon='external-link-alt' />
						{`http://clauditpublic.web.app/rooms/${element.id}`}
						</Button>
					</>
						:
						<QRCode value={'123456'} color={'#eeeee'} />
					}
				</Col>
			</Row>
		</>
	)
}




//	JSON.stringify({ id: element.id, cdc: element.cdc, num: element.name, tipologia: element.roomtype ? element.roomtype.name : '', dimensione: element.size, 'area di rischio': getBaseObjectValue(element.risk_area, 'name', locale), 'tipologia pavimentazione': getBaseObjectValue(element.floor, 'name', locale), parent_list: element.parent_list })




export default translate()(ModalRoomItem)