import React, { useState, useContext, useEffect, useRef, useLayoutEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, Container, ButtonGroup, Row, Col, Form, Badge, Button, Card, InputGroup, Spinner, Nav, Tab, Image, OverlayTrigger, Tooltip } from 'react-bootstrap';
import LocaleContext from '../components/contexts/locale.js'
import { translate } from 'react-polyglot'
import DateTime from 'react-datetime';
import { format, add, subDays, differenceInMonths } from 'date-fns'
import { useHistory } from "react-router-dom";
import CurrencyInput from 'react-currency-input-field';

import DomainContext from '../components/contexts/domainContext'
import GlobalDataContext from '../components/contexts/globaldataContext.js';
import { InitContractHelper } from "../util/contract"

import Footer from "../components/Footer"
import PhoneList from "../components/PhoneList.js"
import ModalBaseObject from "../components/ModalBaseObject"
import ElementFormMedia from "../components/FormMedia"
import ElementFormUsers from '../components/FormUsers'
import AlertSection from "../components/AlertSection.js"
import DeleteConfirmationSection from "../components/DeleteConfirmationSection.js"
import { renderPhoneList, RiskareaBadge } from '../components/StructureGraphicElements.js'
import ServiceamountList, { ServiceamountHourList } from "../components/ServiceAmount"
import { ModalLoadOneContract } from "../components/page_sections/contract_common"

import { getSelectedElement, getValue, myTimestampToDate, compareOrder, compareName, checkComponentPresence, getElementPreview, getAmountValue } from "../util/ui_utils.js"

import { getBaseObjectTypeList as getBaseObjectTypeList2 } from "../Firebase2/Storage2/dbBaseObject"
import { getServiceList as getServiceList2 } from '../Firebase2/Storage2/dbService';
import { getContractListExpanded as getContractListExpanded2, addContract as addContract2, editContract as editContract2 } from '../Firebase2/Storage2/dbContract';
import { getStructureList as getStructureList2 } from '../Firebase2/Storage2/dbBaseCompany';
import { getSystemGroupList as getSystemGroupList2 } from '../Firebase2/Storage2/dbGroup';

import { getTemplateList, contractFromContract, contractFromTemplate, templateFromContract } from "../components/FirebaseApi/template_api.tsx"

import firebase from 'firebase/app'


let licence_status = true

const popoverTiming = { show: 250, hide: 100 }

const newcontract = {
    code: '',
    name: '',
    company: {},
    logo: '',
    website: '',
    structure: {},
    extension_month: 0,
    extension_month_active: false,
    geographic_area: '',
    auction_base: 0,
    rebate_rate: 0,
    object: '',
    ownedBy: [],
    startDate: firebase.firestore.Timestamp.now(),
    endDate: firebase.firestore.Timestamp.now(),
    creation_date: firebase.firestore.Timestamp.now(),
    status: 'draft',
    type: 'hospital',
    scope: 'public',
    sector: 'hospital',
    cig: '',
    risk_area_amount: {},
    serviceamount_list: [],
    servicehouramount_list: [],
    day_alert: 15,
    yeld_mq: 18,
    autocheck: [],
    contradictory: [],
    client_checks: [],
    check_mode: 'complete',
    is_archived: false,
}
const newcompany = {
    name: '',
    code: '',
    vat: '',
    cf: '',
    address: {
        street: '',
        number: '',
        zip: '',
        city: '',
        state: ''
    },
    phone_list: [],
    email: '',
    pec: '',
    website: '',
    logo: '',
    referent_list: [],
    bank: '',
    payment_mode: '',
    sdi: '',
    note: '',
    supply_list: [],
    structure_list: []
}
const status_list = [
    'draft',
    'testing',
    'operational',
    'extension',
    'expired',
]


const contract_scope_icons = {
    "hospital": "./icone_contratto/ospedale.png",
    "retirement": "./icone_contratto/casariposo.png",
    "clinic": "./icone_contratto/poliambulatorio.png",
    "community": "./icone_contratto/comunita.png",
    "school": "./icone_contratto/scuola.png",
    "office": "./icone_contratto/ufficio.png",
    "shopping_center": "./icone_contratto/centrocommerciale.png",
    "bank": "./icone_contratto/banca.png",
    "railroad": "./icone_contratto/ferrovia.png",
    "highway": "./icone_contratto/autostrada.png",
    "airport": "./icone_contratto/aeroporto.png",
    "hotel": "./icone_contratto/hotel.png",
    "catering": "./icone_contratto/ristorante.png",
    "prison": "./icone_contratto/carcere.png",
}



const getContractDescription = (i, t) => {
    if (contract_scope_icons[i.scope]) {
        return (
            <>
                <img height="20" src={contract_scope_icons[i.scope]} />
                &nbsp;
                {t('contract.labels.type_' + i.type)}
                &nbsp;
                {t('contract.labels.scope_' + i.sector)}
                &nbsp;
                <i>({t('contract.labels.sector_' + i.scope)})</i>
            </>
        )
    }

}

const checkDate = (endDate, extension, extension_active) => {
    let final_date = endDate.toDate()
    if (extension && extension_active) {
        final_date = add(endDate.toDate(), { months: extension })
    }

    if (new Date() >= final_date) {
        return 1
    } else if (new Date() >= endDate.toDate()) {
        return 0
    } else {
        return -1
    }

}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const ContractDashboard = ({ t, uData, fromMenu }) => {
    const { domain, domain_data } = useContext(DomainContext)
    console.log("DOMAIN contractdasboard", domain, domain_data)
    let userData = uData

    const childRefAlert = useRef()
    const childRefDelete = useRef()
    const history = useHistory()
    const { initContract } = InitContractHelper()

    //	 const { locale } = useContext(LocaleContext)
    const [element_list, setElementList] = useState([])
    const [loading, setLoading] = useState(false)

    const [showContractWizard, setShowContractWizard] = useState(false)
    const [showContractTemplate, setShowContractTemplate] = useState(false)
    const [canWrite, setWritePage] = useState(false)
    //    const [template_list, setTemplateList] = useState([])
    const [showFilter, setShowFilter] = useState(false)
    const [filterData, setFilterData] = useState({ geographic_area: [], type: [] })
    const [filterAppliedList, setFilterAppliedList] = useState({ geographic_area: [], type: [] })




    //filter
    const handleCloseFilter = () => {
        setShowFilter(false)
    }

    const resetAppliedFilter = (section) => {
        setFilterAppliedList({ ...filterAppliedList, [section]: [] })
        setShowFilter(false)
    }


    const updateAppliedFilter = (section, elem) => {
        const c = filterAppliedList[section].indexOf(elem)
        console.log('index', c)
        let f_data = [...filterAppliedList[section]]
        if (c === -1) {
            f_data.push(elem)
        } else {
            f_data.splice(c, 1)
        }
        setFilterAppliedList({ ...filterAppliedList, [section]: f_data })
    }


    const [isGlobal, setIsGlobal] = useState(false)





    /*     const handleShowNew = () => {
            setCurrentElement(newcontract)
            setNew(true)
            setShow(true)
            setEditedElement(false)
        }
    
        const handleShowEdit = async (el, wizard) => {
            let message_group_list = (domain === 'old') ? await getMessageGroupList(el.id) : await getMessageGroupList2(domain, el.id)
            message_group_list = message_group_list.sort(compareName)
            setUiList({ ...ui_list, message_group_list: message_group_list })
            const newlist = (domain === 'old') ? await getStructureList(el.id) : await getStructureList2(domain, el.id)
            let risk_area_summary = {}
            newlist.map(s => {
                Object.keys(s.risk_area_summary).map(ra => {
                    if (risk_area_summary[ra]) {
                        risk_area_summary[ra].size += s.risk_area_summary[ra].size
                        risk_area_summary[ra].count += s.risk_area_summary[ra].count
                    } else {
                        risk_area_summary[ra] = { ...ui_list.risk_area_dict[ra], size: s.risk_area_summary[ra].size, count: s.risk_area_summary[ra].count }
                    }
                    return true
                })
                return true
            })
            console.log("RISK_AREA", risk_area_summary)
            el.risk_area_summary = risk_area_summary
            setCurrentElement(el)
            setStructureList(newlist)
            let service_list = (domain === 'old') ? await getServiceList(el.id) : await getServiceList2(domain, el.id)
            service_list.sort(compareName)
            setServiceList(service_list)
            setNew(false)
            setShow(true)
            if (!wizard) {
                setEditedElement(false)
            }
        }
     */




    const [ui_list, setUiList] = useState({})
    const initValues = async () => {

        //    userData = JSON.parse(localStorage.getItem('userData'))
        console.log("cotractdashboard, USERDATA", userData)
        const company_list = []
        const structure_list = []
        const message_group_list = []
        setLoading(true)
        if (domain) {
            if (myTimestampToDate(getValue(domain_data, 'endDate')) < new Date()) {
                licence_status = false
            }
            let risk_area_list = await getBaseObjectTypeList2(domain, 'risk_areas')
            //            console.log("RISK_AREA LIST", risk_area_list)
            if (risk_area_list) {
                risk_area_list.sort(compareOrder)
            }
            const referent_role_list = await getBaseObjectTypeList2(domain, 'referent_role')
            let glist = await getSystemGroupList2()
            let group_dict = {}
            glist.map(g => (group_dict[g.id] = g))
            let risk_area_dict = Object.assign({}, ...risk_area_list.map((x) => ({ [x.Id]: x })))
            let filter_data = { geographic_area: [], type: [] }
            let newelement_list = []
            let templ_list = await getTemplateList(false)
            let template_list = []
            if (templ_list) {
                try {
                    template_list = templ_list.templateList.sort(compareName)
                } catch (err) {
                    template_list = []

                }
            } else {
                templ_list = []
            }
            console.log("template_list", template_list)
            setUiList({ ...ui_list, company_list, structure_list, risk_area_list, risk_area_dict, referent_role_list, group_list: glist, group_dict: group_dict, message_group_list, template_list })
            if (domain !== 'old' && userData) {

                if (userData) {

                    if (userData.isGlobalAdmin || ((!userData.contract_dict || userData.contract_dict.length === 0) && (userData.write_page.length || userData.write_page.length > 0))) {

                        newelement_list =  await getContractListExpanded2(domain)
                        console.log('ALL contract list', newelement_list)
                        setIsGlobal(true)
                    } else {

                        console.log("CONTRACTDATA not globaladmin userData", userData)
                        if (userData.contract_dict) {
                            newelement_list =  await getContractListExpanded2(domain)
                            newelement_list = newelement_list.filter(m => (Object.keys(userData.contract_dict).indexOf(m.id) !== -1))
                        }
                    }
                }
            } else {
                newelement_list =  await getContractListExpanded2(domain)
                setIsGlobal(true)

            }
            newelement_list.map(c => {
                if (filter_data.geographic_area.indexOf(c.geographic_area) === -1) {
                    filter_data.geographic_area.push(c.geographic_area)
                }
                if (filter_data.type.indexOf(c.type) === -1) {
                    filter_data.type.push(c.type)
                }
            })
            setFilterData(filter_data)
            newelement_list = newelement_list.sort(compareName)
            if (newelement_list.length === 1) {
                const el = newelement_list[0]
                const curId = el.id
                setLoading(true)
                const ret_data = await initContract(curId)
                history.push("/"+ret_data.id + "/home");

            } else {
                setLoading(false)
                setElementList(newelement_list)
            }

        }
    }



    useLayoutEffect(() => {
        console.log("Contract userData", userData)
    }, [userData])



    useEffect(() => {

        initValues()
        //}, [domain])
    }, [])






    return (
        <>
            <DeleteConfirmationSection ref={childRefDelete} />
            <AlertSection ref={childRefAlert} />
            <Container fluid className="bg-white ">
                <Row>
                    <Col className="text-center">
                        {element_list && element_list.length > 1 ?
                            <ButtonGroup>
                                <Button size="sm" variant="secondary" onClick={() => setShowFilter(true)}><FontAwesomeIcon icon="filter" /> {t('global.labels.filters')}</Button>
                                {filterAppliedList.geographic_area.length > 0 ?
                                    <>
                                        {filterAppliedList.geographic_area.map((g, k) => (
                                            <Button key={k} size="sm" variant="secondary" onClick={(e) => updateAppliedFilter('geographic_area', g)}>
                                                {g.length > 0 ? g : <i>non impostata</i>}
                                            </Button>
                                        ))}
                                        <Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('geographic_area')}><FontAwesomeIcon icon="times" /></Button>
                                    </>
                                    : ''}
                                {filterAppliedList.type.length > 0 ?
                                    <>
                                        {filterAppliedList.type.map((g, k) => (
                                            <Button key={k} size="sm" variant="secondary" onClick={(e) => updateAppliedFilter('type', g)}>
                                                {g}
                                            </Button>
                                        ))}

                                        <Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('type')}> <FontAwesomeIcon icon="times" /></Button>
                                    </>
                                    : ''}
                            </ButtonGroup>
                            : ''}

                    </Col>
                </Row>
            </Container>
            <Container fluid>
                {loading ?
                    <Row>
                        <Col className="text-center">
                            <Spinner animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        </Col>
                    </Row>
                    :
                    <>
                        {element_list && element_list.length > 0 ?
                            <>

                                <Row>

                                    {element_list.filter(c => (
                                        !c.is_archived && (

                                            ((filterAppliedList.geographic_area.length === 0 || filterAppliedList.geographic_area.indexOf(c.geographic_area) !== -1)
                                                &&
                                                (filterAppliedList.type.length === 0 || filterAppliedList.type.indexOf(c.type) !== -1))
                                        )
                                    )
                                    ).map((i, key) => (
                                        <Col key={key} sm="12" md="6" xl="3" className="mt-2">
                                            <OneContract contract={i} t={t} canWrite={canWrite} isGloba={isGlobal} setUiList={setUiList} ui_list={ui_list} setElementList={setElementList} element_list={element_list} fromMenu={fromMenu} />
                                        </Col>
                                    ))}


                                </Row>
                            </>
                            : <> <Row><Col className="font-italic">
                                {t('contracts.messages.no_contract')}
                            </Col>
                            </Row>
                            </>
                        }
                    </>
                }













                <Modal show={showFilter} onHide={handleCloseFilter} size="xl">
                    <Modal.Header>
                        <Modal.Title>
                            <FontAwesomeIcon icon="filter" /> {t('global.labels.filters')}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <Row>
                                <Col>
                                    {t('contracts.labels.filter_geographics')}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {filterData && filterData.geographic_area ?
                                        <ButtonGroup>
                                            {filterData.geographic_area.map((g, k) => (
                                                <Button key={k} size="sm" variant={filterAppliedList['geographic_area'].indexOf(g) === -1 ? 'outline-secondary' : "secondary"} onClick={(e) => updateAppliedFilter('geographic_area', g)}>
                                                    {g.length > 0 ? g : <i>non impostata</i>}
                                                </Button>
                                            ))}
                                            {filterAppliedList.geographic_area.length > 0 ?
                                                <Button variant="outline-secondary" size="sm" onClick={() => setFilterAppliedList({ ...filterAppliedList, 'geographic_area': [] })}><FontAwesomeIcon icon="times" /></Button>
                                                : ''}
                                        </ButtonGroup>
                                        : ''}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {t('contracts.labels.filter_type')}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {filterData && filterData.type ?
                                        <ButtonGroup>
                                            {filterData.type.map((g, k) => (
                                                <Button key={k} size="sm" variant={filterAppliedList['type'].indexOf(g) === -1 ? 'outline-secondary' : "secondary"} onClick={(e) => updateAppliedFilter('type', g)}>
                                                    {t('contract.labels.type_' + g)}
                                                </Button>
                                            ))}
                                            {filterAppliedList.type.length > 0 ?
                                                <Button variant="outline-secondary" size="sm" onClick={() => setFilterAppliedList({ ...filterAppliedList, 'type': [] })}><FontAwesomeIcon icon="times" /></Button>
                                                : ''}
                                        </ButtonGroup>
                                        : ''}

                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseFilter}>
                            {t('modal.close')}
                        </Button>
                    </Modal.Footer>
                </Modal>










            </Container>

            <Footer />
        </ >
    );
}








///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const OneContract = ({ contract, t, ui_list, setUiList, element_list, setElementList, canWrite, isGlobal, fromMenu }) => {

    return (
        <>
            <Card className="h-100">
                <Card.Header bg={(contract.endDate.toDate() < new Date() ? "secondary" : "")}
                    className={(checkDate(contract.endDate, contract.extension_month, contract.extension_month_active) === 0 ? "text-warning" : (checkDate(contract.endDate, contract.extension_month, contract.extension_month_active) === 1 ? "text-danger" : ""))}
                >
                    <b>{contract.name}</b>
                    {checkDate(contract.endDate, contract.extension_month, contract.extension_month_active) === 0 ?
                        <Spinner animation="grow" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                        : ''
                    }
                </Card.Header>
                <Card.Body>
                    <Container fluid >

                        {/*}
                        <Row className="">
                            <Col>
                                {t('contract.labels.start')}
                            </Col>
                            <Col >
                                {t('contract.labels.end')}
                            </Col>
                            {contract.extension_month && contract.extension_month_active && contract.extension_month > 0 ?
                                <Col>
                                    {t('contract.labels.extension_month')}
                                </Col>
                                : ''}
                        </Row>
                         <Row className="border-bottom mb-1 pb-2 font-weight-bold">
                            <Col className="text-info">
                                {format(getMyDate(contract.startDate), 'dd/MM/yyyy')}
                            </Col>
                            <Col className={` ` + (checkDate(contract.endDate, contract.extension_month, contract.extension_month_active) === 0 ? "text-warning" : (checkDate(contract.endDate, contract.extension_month, contract.extension_month_active) === 1 ? "text-danger" : "text-info"))}>
                                {format(getMyDate(contract.endDate), 'dd/MM/yyyy')}
                            </Col>
                            {contract.extension_month && contract.extension_month_active && contract.extension_month > 0 ?
                                <Col className={` ` + (checkDate(contract.endDate, contract.extension_month, contract.extension_month_active) === 0 ? "text-warning" : (checkDate(contract.endDate, contract.extension_month, contract.extension_month_active) === 1 ? "text-danger" : "text-info"))}>
                                    <> {contract.extension_month} {t('global.labels.months')}</>
                                </Col>
                                : ''}
                        </Row> */}
                        <Row>
                            <Col>
                                {contract.company ?
                                    <>    {t('global.labels.basecomany')}: <b>{contract.company.name}</b></>
                                    : ''
                                }
                            </Col>
                            <Col sm="3">{getElementPreview(contract)} </Col>
                        </Row>
                        <Row>
                            <Col>
                                {getContractDescription(contract, t)}
                            </Col>
                        </Row>
                        {/*                         <Row>
                            <Col>
                                {contract.status ?
                                    <>
                                        {t('contract.labels.status_' + contract.status)}
                                    </>
                                    : ''}
                            </Col>
                            <Col>
                            </Col>
                        </Row> */}
                    </Container>
                </Card.Body>
                <Card.Footer>
                    <Row >
                        <Col className="">
                            <ButtonGroup>
                                <ModalLoadOneContract element={contract} t={t} preload_contract={fromMenu} />
                                <ModalContract element={contract} element_list={element_list} setElementList={setElementList} ui_list={ui_list} t={t} canWrite={false} isGlobal={isGlobal} setUiList={setUiList} />
                            </ButtonGroup>
                        </Col>
                        {!contract.structureList || !contract.company || (contract.company.name && contract.company.name.length === 0) ?
                            <Col className="text-right">
                                <FontAwesomeIcon icon='exclamation-circle' /> <span className="text-muted">inserimento dati non completo</span>
                            </Col>
                            : ''
                        }

                    </Row>

                </Card.Footer>
            </Card>
        </>
    )

}


///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const ModalDomainUser = ({ t, contract_list, ui_list }) => {
    const { domain } = useContext(DomainContext)
    const [showEditUsers, setShowUsers] = useState(false);
    const handleCloseUsers = () => setShowUsers(false);
    const [isInvalid, setIsInvalid] = useState(false)
    const [editedElement, setEditedElement] = useState(false)

    return (
        <>
            <Button variant="outline-info" onClick={() => setShowUsers(true)}>

                <img src="/menu_icons/Gestione_utente.png" height="42"
                    className="d-inline-block align-top mr-1"
                    alt="utenti" />

                {t('navs.pages.users')}</Button>

            <Modal show={showEditUsers} onHide={handleCloseUsers} dialogClassName="custom-modal" backdrop="static" className="bg-secondary">
                <Modal.Header closeButton className={editedElement ? "bg-warning" : ""}>
                    <Modal.Title>
                        {t('navs.pages.users')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <ElementFormUsers t={t} contract_list={contract_list} functions={{ setIsInvalid: setIsInvalid, isInvalid: isInvalid }} ui_list={ui_list} domain={domain} />
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseUsers}>
                        {t('modal.close')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )

}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const ModalContract = ({ is_new, element, setElementList, setElement, element_list, ui_list, t, canWrite, setUiList, isGlobal }) => {
    const { domain, domain_data } = useContext(DomainContext)

    const childRefAlert = useRef()

    const [showEdit, setShow] = useState(false);
    const [newEdit, setNew] = useState(true);
    const [curElement, setCurrentElement] = useState(element)
    const [editedElement, setEditedElement] = useState(false)
    const [loading, setLoading] = useState(false)
    const [structure_list, setStructureList] = useState([])
    const [service_list, setServiceList] = useState([])


    const onUpdateData = (data, b) => {
        console.log("update", data, b)
        //    setUiList({ ...ui_list, [data]: b.list })
    }

    const handleClose = () => setShow(false);


    const handleShowEdit = async (wizard) => {
        setCurrentElement(element)
        const newlist =  await getStructureList2(domain, element.id)
        let risk_area_summary = {}
        newlist.map(s => {
            Object.keys(s.risk_area_summary).map(ra => {
                if (risk_area_summary[ra]) {
                    risk_area_summary[ra].size += s.risk_area_summary[ra].size
                    risk_area_summary[ra].count += s.risk_area_summary[ra].count
                } else {
                    risk_area_summary[ra] = { ...ui_list.risk_area_dict[ra], size: s.risk_area_summary[ra].size, count: s.risk_area_summary[ra].count }
                }
                return true
            })
            return true
        })
        console.log("RISK_AREA", risk_area_summary)
        element.risk_area_summary = risk_area_summary
        setCurrentElement(element)
        setStructureList(newlist)
        let service_list = await getServiceList2(domain, element.id)
        service_list.sort(compareName)
        setServiceList(service_list)
        setNew(false)
        setShow(true)
        if (!wizard) {
            setEditedElement(false)
        }
    }

    const onChangeHandler = (el) => {
        let { name, value, type } = el
        let sub = undefined;
        try {
            sub = el.getAttribute('sub') || el.sub
        } catch (err) { }
        console.log(name, value, type, sub)
        if (type === 'radio') {
            if (value === 'true') {
                value = true
            } else if (value === 'false') {
                value = false
            }
        } else if (type === 'checkbox') {
            let oldcheck = []
            try {
                oldcheck = [...curElement[name]]
            } catch (err) { }
            if (el.checked) {
                if (oldcheck && oldcheck.indexOf(value) < 0) {
                    oldcheck.push(value)
                } else {
                    oldcheck = [value]
                }
            } else {
                if (oldcheck.indexOf(value) >= 0) {
                    oldcheck.splice(oldcheck.indexOf(value), 1)
                }
            }
            value = oldcheck
        }
        if (sub) {
            value = { ...curElement[sub], [name]: value }
            name = sub;
        }
        console.log(name, value)
        setCurrentElement({ ...curElement, [name]: value })
        setEditedElement(true)
    }



    const onChangeHandlerCompany = (el) => {
        let { name, value, type } = el
        let sub = undefined;
        try {
            sub = el.getAttribute('sub') || el.sub
        } catch (err) { }
        console.log(name, value, type, sub)
        let editCompany = newcompany
        if (!curElement.company) {
            editCompany = { ...curElement.company }
        }
        if (type === 'radio') {
            if (value === 'true') {
                value = true
            } else if (value === 'false') {
                value = false
            }
        } else if (type === 'checkbox') {
            let oldcheck = []
            try {
                oldcheck = [...editCompany[name]]
            } catch (err) { }
            if (el.checked) {
                if (oldcheck.indexOf(value) < 0) {
                    oldcheck.push(value)
                }
            } else {
                if (oldcheck.indexOf(value) >= 0) {
                    oldcheck.splice(oldcheck.indexOf(value), 1)
                }
            }
            value = oldcheck
        }
        if (sub) {
            value = { ...editCompany[sub], [name]: value }
            name = sub;
        }
        editCompany[name] = value
        console.log(name, value)
        console.log(editCompany)
        setCurrentElement({ ...curElement, company: editCompany })
        setEditedElement(true)
    }
















    return (
        <>
            {canWrite ?
                <Button className="mr-1" variant="info" size="sm" onClick={() => handleShowEdit()}><FontAwesomeIcon icon="pencil-alt" /></Button>
                :
                <Button className="mr-1" variant="info" size="sm" onClick={() => handleShowEdit()}><FontAwesomeIcon icon="eye" /></Button>
            }
            {showEdit ?
                <Modal key={"dlg_edit"} show={showEdit} onHide={handleClose} dialogClassName="custom-modal" backdrop="static" className="bg-secondary">
                    <Modal.Header closeButton className={editedElement ? "bg-warning" : ""}>
                        <Modal.Title>
                            {newEdit ?
                                <>
                                    <FontAwesomeIcon icon="plus-square" /> <span className="text-primary">{t('contract.labels.new_contract')}</span>
                                </>
                                :
                                [canWrite ?
                                    <>
                                        <FontAwesomeIcon icon="pencil-alt" /> <span className="text-info">{t('contract.labels.edit_contract')}</span>
                                    </>
                                    :
                                    <>
                                        <FontAwesomeIcon icon="eye" /> <span className="text-info">{t('contract.labels.info_contract')}</span>
                                    </>
                                ]
                            }
                            : {curElement.name}&nbsp;
                            {editedElement ? <FontAwesomeIcon icon="save" /> : ''}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <AlertSection ref={childRefAlert} />
                        <Container fluid>
                            <Tab.Container id="left-tabs-example" defaultActiveKey="tabDetails">
                                <Row>
                                    <Col sm={3} >
                                        <Nav variant="pills" className="flex-column">
                                            <Nav.Item variant="secondary" className="mb-1">
                                                <Nav.Link eventKey="tabDetails">{t('company.labels.details')} </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item className="mb-1">
                                                <Nav.Link eventKey="tabCompany">{t('company.labels.client')} </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item className="mb-1">
                                                <Nav.Link eventKey="tabAmounts">{t('global.labels.amounts')}</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item className="mb-1">
                                                <Nav.Link eventKey="tabReferents">{t('global.labels.referents')}</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item className="mb-1">
                                                <Nav.Link eventKey="tabChecks">{t('global.labels.checks')}</Nav.Link>
                                            </Nav.Item>

                                            <Nav.Item className="mb-1">
                                                <Nav.Link variant="secondary" eventKey="tabMedia" disabled={newEdit}>{t('contracts.labels.imagesdocs')} </Nav.Link>
                                            </Nav.Item>

                                        </Nav>
                                    </Col>
                                    <Col>
                                        <Tab.Content>
                                            <Tab.Pane eventKey="tabDetails">
                                                <ElementFormDetails element={curElement} t={t} functions={{ edit: onChangeHandler }} canWrite={canWrite} />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="tabCompany">
                                                <ElementFormCompany elem={curElement} t={t} functions={{ edit: onChangeHandlerCompany }} canWrite={canWrite} />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="tabAmounts">
                                                <ElementFormAmounts disabled={newEdit} element={curElement} t={t} ui_list={ui_list} functions={{ edit: onChangeHandler }} service_list={service_list} canWrite={canWrite} />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="tabReferents">
                                                <ElementFormReferent element={curElement} t={t} ui_list={ui_list} functions={{ edit: onChangeHandler, updateData: onUpdateData }} canWrite={canWrite} />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="tabChecks">
                                                <ElementFormChecks disabled={newEdit} element={curElement} t={t} ui_list={ui_list} functions={{ edit: onChangeHandler }} service_list={service_list} canWrite={canWrite} />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="tabMedia">
                                                <ElementFormMedia element={curElement} t={t} ui_list={ui_list} functions={{ edit: onChangeHandler, save: alert }} section='contract' canWrite={canWrite} />
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            {t('modal.close')}
                        </Button>
                    </Modal.Footer>
                </Modal>


                : ''}



        </>
    )

}




///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function ElementFormDetails({ element, functions, ui_list, t, canWrite }) {
    const { locale } = useContext(LocaleContext)
    return (
        <>
            <Form.Group as={Row} controlId="contractName" className="border-bottom mb-1">
                <Form.Label column sm="2">{t('global.labels.code')}</Form.Label>
                <Col sm="8">
                    <Form.Control type="text" name="code" value={element.code} onChange={(event) => functions.edit(event.target)} className="mb-2" />
                    <Form.Text className="text-muted">
                    </Form.Text>
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="contractName" className="border-bottom mb-1">
                <Form.Label column sm="2">{t('global.labels.name')}</Form.Label>
                <Col sm="8">
                    <Form.Control type="text" name="name" value={element.name} onChange={(event) => functions.edit(event.target)} className="mb-2" />
                    <Form.Text className="text-muted">
                    </Form.Text>
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="contractName" className="border-bottom mb-1">
                <Form.Label column sm="2">{t('global.labels.geographic_area')}</Form.Label>
                <Col sm="8">
                    <Form.Control type="text" name="geographic_area" value={element.geographic_area} onChange={(event) => functions.edit(event.target)} className="mb-2" />
                    <Form.Text className="text-muted">
                    </Form.Text>
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="contractName" className="border-bottom mb-1">
                <Form.Label column sm="2">{t('global.labels.object')}</Form.Label>
                <Col sm="8">
                    <Form.Control as="textarea" rows="3" name="object" value={element.object} onChange={(event) => functions.edit(event.target)} className="mb-2" />
                </Col>
            </Form.Group>
            {element.sector === 'public' ?
                <Form.Group as={Row} controlId="contractName" className="border-bottom mb-1">
                    <Form.Label column sm="2">{t('global.labels.cig')}</Form.Label>
                    <Col sm="8">
                        <Form.Control as="text" rows="3" name="cig" value={element.cig} onChange={(event) => functions.edit(event.target)} className="mb-2" />
                    </Col>
                </Form.Group>
                : ''
            }
            <Form.Group as={Row} controlId="eventStart" className="border-bottom">
                <Form.Label column sm="2">{t('contract.labels.length')}</Form.Label>
                <Col sm="3">
                    <DateTime initialValue={myTimestampToDate(element.startDate)} dateFormat='DD/MM/YYYY' timeFormat={false} name="startDate" locale="it" closeOnSelect={true} onChange={(e) => functions.edit({ name: 'startDate', value: firebase.firestore.Timestamp.fromDate(e.toDate()) })} />
                    <Form.Text className="text-muted">
                        {t('contract.labels.start')}
                    </Form.Text>
                </Col>
                <Col sm="3">
                    <DateTime initialValue={myTimestampToDate(element.endDate)} dateFormat='DD/MM/YYYY' timeFormat={false} name="endDate" locale="it" closeOnSelect={true} onChange={(e) => functions.edit({ name: 'endDate', value: firebase.firestore.Timestamp.fromDate(e.toDate()) })} />
                    <Form.Text className="text-muted">
                        {t('contract.labels.end')}
                    </Form.Text>
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="eventStart" className="border-bottom">
                <Form.Label column sm="2">{t('contract.labels.extension')}</Form.Label>
                <Col sm="2">
                    <Form.Check type="radio" name="extension_month_active" value={true} checked={element.extension_month_active} label={t('global.labels.yes')} onChange={(event) => functions.edit(event.currentTarget)} />
                    <Form.Check type="radio" name="extension_month_active" value={false} checked={!element.extension_month_active} label={t('global.labels.no')} onChange={(event) => functions.edit(event.currentTarget)} />
                    <Form.Text className="text-muted">
                        {t('contract.labels.active')}
                    </Form.Text>
                </Col>
                <Col sm="3">
                    <InputGroup className="mb-2" >
                        <Form.Control type="number" min="0" name="extension_month" value={element.extension_month} onChange={(event) => functions.edit(event.target)} />
                        <InputGroup.Text>
                            {t('global.labels.months')}
                        </InputGroup.Text>
                    </InputGroup>
                    <Form.Text className="text-muted">
                        {t('contract.labels.length')}
                    </Form.Text>
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="contractName" className="border-bottom mb-1">
                <Form.Label column sm="2">{t('global.labels.day_alert')}</Form.Label>
                <Col sm="8">
                    <Form.Control type="number" min="15" name="day_alert" value={element.day_alert} onChange={(event) => functions.edit(event.target)} className="mb-2" />
                    <Form.Text className="text-muted">
                    </Form.Text>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="border-bottom">
                <Form.Label column sm="2">{t('global.labels.type')}</Form.Label>
                <Col>
                    <Form.Check type="radio" name="type" value="hospital" checked={element.type === 'hospital'} label={t('contract.labels.type_hospital')} onChange={(event) => functions.edit(event.currentTarget)} />
                    <Form.Check type="radio" name="type" value="civil" checked={element.type === 'civil'} label={t('contract.labels.type_civil')} onChange={(event) => functions.edit(event.currentTarget)} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="border-bottom">
                <Form.Label column sm="2">{t('global.labels.scope')}</Form.Label>
                {element.type === 'hospital' ?
                    <Col>
                        <Form inline>
                            <Form.Label>
                                <img height="20" alt="" src={contract_scope_icons['hospital']} />
                            </Form.Label>
                            <Form.Check type="radio" name="scope" value="hospital" checked={element.scope === 'hospital'} label={t('contract.labels.sector_hospital')} onChange={(event) => functions.edit(event.currentTarget)} />
                        </Form>
                        <Form inline>
                            <Form.Label>
                                <img height="20" alt="" src={contract_scope_icons['retirement']} />
                            </Form.Label>
                            <Form.Check type="radio" name="scope" value="retirement" checked={element.scope === 'retirement'} label={t('contract.labels.sector_retirement')} onChange={(event) => functions.edit(event.currentTarget)} />
                        </Form>
                        <Form inline>
                            <Form.Label>
                                <img height="20" alt="" src={contract_scope_icons['clinic']} />
                            </Form.Label>
                            <Form.Check type="radio" name="scope" value="clinic" checked={element.scope === 'clinic'} label={t('contract.labels.sector_clinic')} onChange={(event) => functions.edit(event.currentTarget)} />
                        </Form>
                        <Form inline>
                            <Form.Label>
                                <img height="20" alt="" src={contract_scope_icons['community']} />
                            </Form.Label>
                            <Form.Check type="radio" name="scope" value="community" checked={element.scope === 'community'} label={t('contract.labels.sector_community')} onChange={(event) => functions.edit(event.currentTarget)} />
                        </Form>
                    </Col>
                    :
                    <Col>
                        <Form inline>
                            <Form.Label>
                                <img height="20" alt="" src={contract_scope_icons['school']} />
                            </Form.Label>
                            <Form.Check type="radio" name="scope" value="school" checked={element.scope === 'school'} label={t('contract.labels.sector_school')} onChange={(event) => functions.edit(event.currentTarget)} />
                        </Form>
                        <Form inline>
                            <Form.Label>
                                <img height="20" alt="" src={contract_scope_icons['office']} />
                            </Form.Label>
                            <Form.Check type="radio" name="scope" value="office" checked={element.scope === 'office'} label={t('contract.labels.sector_office')} onChange={(event) => functions.edit(event.currentTarget)} />
                        </Form>
                        <Form inline>
                            <Form.Label>
                                <img height="20" alt="" src={contract_scope_icons['shopping_center']} />
                            </Form.Label>
                            <Form.Check type="radio" name="scope" value="shopping_center" checked={element.scope === 'shopping_center'} label={t('contract.labels.sector_shopping_center')} onChange={(event) => functions.edit(event.currentTarget)} />
                        </Form>
                        <Form inline>
                            <Form.Label>
                                <img height="20" alt="" src={contract_scope_icons['bank']} />
                            </Form.Label>
                            <Form.Check type="radio" name="scope" value="bank" checked={element.scope === 'bank'} label={t('contract.labels.sector_bank')} onChange={(event) => functions.edit(event.currentTarget)} />
                        </Form>
                        <Form inline>
                            <Form.Label>
                                <img height="20" alt="" src={contract_scope_icons['railroad']} />
                            </Form.Label>
                            <Form.Check type="radio" name="scope" value="railroad" checked={element.scope === 'railroad'} label={t('contract.labels.sector_railroad')} onChange={(event) => functions.edit(event.currentTarget)} />
                        </Form>
                        <Form inline>
                            <Form.Label>
                                <img height="20" alt="" src={contract_scope_icons['highway']} />
                            </Form.Label>
                            <Form.Check type="radio" name="scope" value="highway" checked={element.scope === 'highway'} label={t('contract.labels.sector_highway')} onChange={(event) => functions.edit(event.currentTarget)} />
                        </Form>
                        <Form inline>
                            <Form.Label>
                                <img height="20" alt="" src={contract_scope_icons['airport']} />
                            </Form.Label>
                            <Form.Check type="radio" name="scope" value="airport" checked={element.scope === 'airport'} label={t('contract.labels.sector_airport')} onChange={(event) => functions.edit(event.currentTarget)} />
                        </Form>
                        <Form inline>
                            <Form.Label>
                                <img height="20" alt="" src={contract_scope_icons['hotel']} />
                            </Form.Label>
                            <Form.Check type="radio" name="scope" value="hotel" checked={element.scope === 'hotel'} label={t('contract.labels.sector_hotel')} onChange={(event) => functions.edit(event.currentTarget)} />
                        </Form>
                        <Form inline>
                            <Form.Label>
                                <img height="20" alt="" src={contract_scope_icons['catering']} />
                            </Form.Label>
                            <Form.Check type="radio" name="scope" value="catering" checked={element.scope === 'catering'} label={t('contract.labels.sector_catering')} onChange={(event) => functions.edit(event.currentTarget)} />
                        </Form>
                        <Form inline>
                            <Form.Label>
                                <img height="20" alt="" src={contract_scope_icons['prison']} />
                            </Form.Label>
                            <Form.Check type="radio" name="scope" value="prison" checked={element.scope === 'prison'} label={t('contract.labels.sector_prison')} onChange={(event) => functions.edit(event.currentTarget)} />
                        </Form>

                    </Col>
                }
            </Form.Group>
            <Form.Group as={Row} className="border-bottom">
                <Form.Label column sm="2">{t('global.labels.sector')}</Form.Label>
                <Col>
                    <Form.Check type="radio" name="sector" value="private" checked={element.sector === 'private'} label={t('contract.labels.scope_private')} onChange={(event) => functions.edit(event.currentTarget)} />
                    <Form.Check type="radio" name="sector" value="public" checked={element.sector === 'public'} label={t('contract.labels.scope_public')} onChange={(event) => functions.edit(event.currentTarget)} />
                </Col>
            </Form.Group>
            {element.scope === 'public' ?
                <Form.Group as={Row} controlId="contractName" className="border-bottom mb-1">
                    <Form.Label column sm="2">{t('global.labels.cig')}</Form.Label>
                    <Col sm="8">
                        <Form.Control type="text" name="cig" value={element.cig} onChange={(event) => functions.edit(event.target)} className="mb-2" />
                        <Form.Text className="text-muted">
                        </Form.Text>
                    </Col>
                </Form.Group>
                : ''
            }
            <Form.Group as={Row} controlId="eventStart" className="border-bottom pb-1">
                <Form.Label column sm="2">{t('contract.labels.creation_date')}</Form.Label>
                <Col sm="5">
                    <DateTime className="mb-2" initialValue={myTimestampToDate(element.creation_date)} dateFormat='DD/MM/YYYY' timeFormat={false} locale="it" closeOnSelect={true} name="creation_date" onChange={(e) => functions.edit({ name: 'creation_date', value: firebase.firestore.Timestamp.fromDate(e.toDate()) })} />
                </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formEquipmentBrand" className="border-bottom">
                <Form.Label column sm="2">{t('global.labels.status')}</Form.Label>
                <Col sm="8">
                    <Form.Control as="select" value={element.status} name="status" className="mb-2" onChange={(event) => functions.edit({ name: 'status', type: 'select', value: event.target.value })}  >
                        {status_list.map((f, k) => (
                            <option key={k} value={f} >{t('contract.labels.status_' + f)}</option>
                        ))}
                    </Form.Control>
                </Col>
            </Form.Group>

        </>
    )
}









///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function ElementFormChecks({ element, functions, ui_list, t, service_list, canWrite }) {

    return (
        <>
            <Form.Group as={Row} className="border-bottom mb-1 pb-1">
                <Form.Label column sm="2">{t('contract.labels.autocheck')}</Form.Label>
                <Col>
                    <Form.Check type="checkbox" name="autocheck" value="results" checked={element.autocheck && element.autocheck.indexOf('results') >= 0} label={t('contract.labels.checkresults')} onChange={(event) => functions.edit(event.currentTarget)} />
                </Col>
                <Col>
                    <Form.Check type="checkbox" name="autocheck" value="process" checked={element.autocheck && element.autocheck.indexOf('process') >= 0} label={t('contract.labels.checkprocess')} onChange={(event) => functions.edit(event.currentTarget)} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="border-bottom mb-1 pb-1">
                <Form.Label column sm="2">{t('contract.labels.contradictory')}</Form.Label>
                <Col>
                    <Form.Check type="checkbox" name="contradictory" value="results" checked={element.contradictory && element.contradictory.indexOf('results') >= 0} label={t('contract.labels.checkresults')} onChange={(event) => functions.edit(event.currentTarget)} />
                </Col>
                <Col>
                    <Form.Check type="checkbox" name="contradictory" value="process" checked={element.contradictory && element.contradictory.indexOf('process') >= 0} label={t('contract.labels.checkprocess')} onChange={(event) => functions.edit(event.currentTarget)} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="border-bottom mb-1 pb-1">
                <Form.Label column sm="2">{t('contract.labels.client_checks')}</Form.Label>
                <Col>
                    <Form.Check type="checkbox" name="client_checks" value="results" checked={element.client_checks && element.client_checks.indexOf('results') >= 0} label={t('contract.labels.checkresults')} onChange={(event) => functions.edit(event.currentTarget)} />
                </Col>
                <Col>
                    <Form.Check type="checkbox" name="client_checks" value="process" checked={element.client_checks && element.client_checks.indexOf('process') >= 0} label={t('contract.labels.checkprocess')} onChange={(event) => functions.edit(event.currentTarget)} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="border-bottom mb-1 pb-1">
                <Form.Label column sm="2">{t('contract.labels.control_mode')}</Form.Label>
                <Col>
                    <Form.Check type="radio" name="check_mode" value="complete" checked={element.check_mode === 'complete'} label={t('contract.labels.check_complete')} onChange={(event) => functions.edit(event.currentTarget)} />
                </Col>
                <Col>
                    <Form.Check type="radio" name="check_mode" value="reduced" checked={element.check_mode === 'reduced'} label={t('contract.labels.check_reduced')} onChange={(event) => functions.edit(event.currentTarget)} />
                </Col>
            </Form.Group>
            {element.type === 'hospital' ?
                <Form.Group as={Row} className="border-bottom mb-1 pb-1">
                    <Form.Label column sm="2">{t('contract.labels.analisys_laboratory')}</Form.Label>
                    <Col>
                        <Form.Control type="text" name="analisys_laboratory" value={element.analisys_laboratory} onChange={(event) => functions.edit(event.target)} className="mb-2" />
                        <Form.Text className="text-muted">
                            Accreditato da ACCREDIA
                        </Form.Text>
                    </Col>
                </Form.Group>
                : ''}
        </>
    )

}



///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function ElementFormAmounts({ element, functions, ui_list, t, service_list, canWrite }) {

    const {contract} = useContext(GlobalDataContext)
    const { locale } = useContext(LocaleContext)
    const [risk_area_amount, setRiskareaAmount] = useState(element.risk_area_amount || [])
    const [risk_area_totalamount, setRiskAreaTotalAmount] = useState({ mq: 0, year: 0, contract: 0, size: 0, month: 0 })
    //    const [service_amount, setServiceAmount] = useState(element.service_amount || [])
    //const [service_hour_amount, setServiceHourAmount] = useState(element.service_hour_amount || [])

    const getContractMonths = () => {
        const value = parseInt(differenceInMonths(myTimestampToDate(element.endDate), myTimestampToDate(element.startDate))) + 1
        let value_ext = 0
        if (element.extension_month && element.extension_month_active) {
            value_ext = parseInt(element.extension_month)
        }
        return [value, value_ext]
    }

    const contract_month = getContractMonths()

    const onChangeRiskAreaAmount = ({ name, value }) => {
        setRiskareaAmount({ ...risk_area_amount, [name]: value })
    }
    /* 
        const onChangeServiceAmount = (el) => {
            setServiceAmount({ ...service_amount, [el.name]: el.value })
        }
        const onChangeServiceRequestAmount = (el) => {
            setServiceAmount({ ...service_amount, [el.name]: el.value })
        } */


    useEffect(() => {
        let temp_total = { mq: 0, year: 0, contract: 0, size: 0, month: 0 }
        try {

            {
                ui_list.risk_area_list.filter(a => Object.keys(element.risk_area_summary).indexOf(a.Id) >= 0).map((f) => {
                    temp_total.size = temp_total.size += element.risk_area_summary[f.Id].size
                    temp_total.mq = temp_total.mq += getAmountValue(risk_area_amount[f.Id])
                    temp_total.month = temp_total.month += getAmountValue(risk_area_amount[f.Id] * element.risk_area_summary[f.Id].size)
                    temp_total.year = temp_total.year += (getAmountValue(risk_area_amount[f.Id] * element.risk_area_summary[f.Id].size) * 12)
                    temp_total.contract = temp_total.contract += (getAmountValue(risk_area_amount[f.Id] * element.risk_area_summary[f.Id].size.toFixed(2)) * contract_month[0])
                })
            }
        } catch (err) { }
        console.log("risk_area_mount total", temp_total)
        setRiskAreaTotalAmount(temp_total)
        functions.edit({ name: 'risk_area_amount', value: risk_area_amount })

    }, [risk_area_amount])

    return (
        <>
            <Card className="mb-3">
                <Card.Header className="h4 text-primary">

							{contract && contract.type ==='civil'?
                                                t('global.labels.standard_cleaning_summary')
                                            :
                                                t('global.labels.risk_area_summary')
                                            }							
                </Card.Header>
                <Card.Body>
                    <Container fluid>
                        {element.risk_area_summary && Object.keys(element.risk_area_summary).length > 0 ?
                            <>
                                <Row className="mb-1">
                                    <Col className="bg-secondary text-light ml-1 text-left">{element.type === 'civil' ? t('global.labels.standard_cleaning') : t('global.labels.risk_area')} </Col>
                                    <Col className="bg-secondary text-light ml-1">{t('contract.labels.value')} / m<sup>2</sup> / mese</Col>
                                    <Col className="bg-secondary text-light ml-1">m<sup>2</sup> totali</Col>
                                    <Col className="bg-secondary text-light ml-1">{t('contract.labels.value')} totale al mese</Col>
                                    <Col className="bg-secondary text-light ml-1">{t('contract.labels.value')} totale annuale<br />

                                        <OverlayTrigger
                                            placement="right"
                                            delay={popoverTiming}
                                            overlay={<Tooltip id="button-tooltip">({t('contract.labels.value')} / m<sup>2</sup> / mese) * m<sup>2</sup> totali * 12</Tooltip>}
                                        >
                                            <FontAwesomeIcon icon="info-circle" />
                                        </OverlayTrigger>
                                    </Col>
                                    <Col className="bg-secondary text-light ml-1"> Importo totale <br /><i>({contract_month[0]} mesi)</i>


                                        <OverlayTrigger
                                            placement="right"
                                            delay={popoverTiming}
                                            overlay={<Tooltip id="button-tooltip">({t('contract.labels.value')} / m<sup>2</sup> / mese) * m<sup>2</sup> totali * {contract_month[0]}</Tooltip>}
                                        >
                                            <FontAwesomeIcon icon="info-circle" />
                                        </OverlayTrigger>
                                    </Col>
                                </Row>
                                {ui_list.risk_area_list.filter(a => Object.keys(element.risk_area_summary).indexOf(a.Id) >= 0).map((f, k) => (
                                    <Row key={k}>
                                        <Col className="text-left">
                                            <RiskareaBadge riskarea={f} />
                                        </Col>
                                        <Col>
                                            <InputGroup className="mb-2" >
                                                <Form.Control type="number" min="0" step="0.1" value={element.risk_area_amount[f.Id]} name={f.Id} onChange={(event) => onChangeRiskAreaAmount(event.currentTarget)} />
                                                <InputGroup.Append>
                                                    <InputGroup.Text>
                                                        &euro;
                                                    </InputGroup.Text>
                                                </InputGroup.Append>
                                            </InputGroup>
                                        </Col>
                                        <Col className="text-right">
                                            {element.risk_area_summary[f.Id].size.toFixed(2)}
                                        </Col>
                                        <Col>
                                            <CurrencyInput
                                                name={f.id}
                                                value={getAmountValue(risk_area_amount[f.Id] * element.risk_area_summary[f.Id].size).toFixed(2)}
                                                allowDecimals={true}
                                                decimalsLimit={2}
                                                decimalSeparator=","
                                                className="form-control text-right"
                                                groupSeparator="."
                                                prefix="€"
                                                disabled={true}
                                                precision={2}
                                            />
                                        </Col>
                                        <Col>
                                            <CurrencyInput
                                                name={f.id}
                                                placeholder="€0.00"
                                                value={getAmountValue(risk_area_amount[f.Id] * element.risk_area_summary[f.Id].size.toFixed(2) * 12).toFixed(2)}
                                                allowDecimals={true}
                                                decimalsLimit={2}
                                                decimalSeparator=","
                                                className="form-control text-right"
                                                groupSeparator="."
                                                prefix="€"
                                                disabled={true}
                                                precision={2}
                                            />
                                        </Col>
                                        <Col>
                                            <CurrencyInput
                                                name={f.id}
                                                value={getAmountValue(risk_area_amount[f.Id] * element.risk_area_summary[f.Id].size.toFixed(2) * contract_month[0]).toFixed(2)}
                                                allowDecimals={true}
                                                decimalsLimit={2}
                                                decimalSeparator=","
                                                className="form-control text-right"
                                                thousandSeparator="."
                                                groupSeparator="."
                                                prefix="€"
                                                disabled={true}
                                                precision={2}
                                            />
                                        </Col>
                                    </Row>))
                                }
                            </>
                            : <Row>
                                <Col className="text-left font-italic">
											{contract.type === 'civil' ?
									t('contracts.messages.no_standard_cleaning')
:
									t('contracts.messages.no_risk_area')
							}
                                </Col>
                            </Row>
                        }

                    </Container>

                </Card.Body>
                {element.risk_area_summary && Object.keys(element.risk_area_summary).length > 0 ?
                    <Card.Footer>
                        <Container fluid>
                            <Row>
                                <Col className="border-right ml-1 text-left">{t('global.labels.total')} </Col>
                                <Col className="border-right ml-1">{risk_area_totalamount.mq}</Col>
                                <Col className="border-right ml-1 text-right">{risk_area_totalamount.size.toFixed(2)}</Col>
                                <Col className="border-right ml-1">
                                    <CurrencyInput
                                        name='total_contract'
                                        value={risk_area_totalamount.month.toFixed(2)}
                                        allowDecimals={true}
                                        decimalsLimit={2}
                                        decimalSeparator=","
                                        className="form-control-plaintext text-right text-dark"
                                        groupSeparator="."
                                        prefix="€"
                                        disabled={true}
                                        precision={2}
                                    />
                                </Col>
                                <Col className="border-right ml-1">
                                    <CurrencyInput
                                        name='total_contract'
                                        value={risk_area_totalamount.year.toFixed(2)}
                                        allowDecimals={true}
                                        decimalsLimit={2}
                                        decimalSeparator=","
                                        className="form-control-plaintext text-right text-dark"
                                        groupSeparator="."
                                        prefix="€"
                                        disabled={true}
                                        precision={2}
                                    />
                                </Col>
                                <Col className="ml-1">
                                    <CurrencyInput
                                        name='total_contract'
                                        value={risk_area_totalamount.contract.toFixed(2)}
                                        allowDecimals={true}
                                        decimalsLimit={2}
                                        decimalSeparator=","
                                        className="form-control-plaintext text-right text-dark"
                                        groupSeparator="."
                                        prefix="€"
                                        disabled={true}
                                        precision={2}
                                    />
                                </Col>
                            </Row>

                        </Container>

                    </Card.Footer>
                    : ''}
            </Card>

            <ServiceamountList element={element} functions={functions} t={t} contract_month={contract_month} canWrite={canWrite} />
            <ServiceamountHourList element={element} functions={functions} t={t} canWrite={canWrite} />


        </>
    )
}


///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function ElementFormCompany({ elem, functions, t, canWrite }) {
    console.log(elem)
    let element = newcompany
    if (elem && elem.company) {
        element = elem.company
    }
    return (
        <>
            <Form.Group as={Row} id="formSupplierBusinessname" className="border-bottom">
                <Form.Label column sm="2">{t('suppliers.labels.businessname')} </Form.Label>
                <Col sm="6">
                    <Form.Control className="mb-2" type="text" name="name" value={getValue(element, 'name')} onChange={(event) => functions.edit(event.currentTarget)} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formSupplierCode" className="border-bottom">
                <Form.Label column sm="2">{t('suppliers.labels.code')}</Form.Label>
                <Col sm="6">
                    <Form.Control className="mb-2" type="text" name="code" value={getValue(element, 'code')} onChange={(event) => functions.edit(event.currentTarget)} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formSupplierVat" className="border-bottom">
                <Form.Label column sm="2">{t('suppliers.labels.vat')}</Form.Label>
                <Col sm="6">
                    <Form.Control className="mb-2" type="text" name="vat" value={getValue(element, 'vat')} onChange={(event) => functions.edit(event.currentTarget)} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formSupplierCf" className="border-bottom">
                <Form.Label column sm="2">{t('suppliers.labels.cf')}</Form.Label>
                <Col sm="6">
                    <Form.Control className="mb-2" type="text" name="cf" value={getValue(element, 'cf')} onChange={(event) => functions.edit(event.currentTarget)} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="supplierAddress" className="border-bottom">
                <Form.Label column sm="2">{t('suppliers.labels.address')} </Form.Label>
                <Col>
                    <Row>
                        <Col>
                            <Form.Control type="text" sub="address" name="street" value={getValue(element.address, 'street')} onChange={(event) => functions.edit(event.currentTarget)} />
                            <Form.Text className="text-muted">
                                {t('global.labels.street')}
                            </Form.Text>
                        </Col>
                        <Col sm="2">
                            <Form.Control type="text" sub="address" name="zip" value={getValue(element.address, 'zip')} onChange={(event) => functions.edit(event.currentTarget)} />
                            <Form.Text className="text-muted">
                                {t('global.labels.zip')}
                            </Form.Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col >
                            <Form.Control type="text" sub="address" name="city" value={getValue(element.address, 'city')} onChange={(event) => functions.edit(event.currentTarget)} />
                            <Form.Text className="text-muted">
                                {t('global.labels.city')}
                            </Form.Text>
                        </Col>
                        <Col sm="2">
                            <Form.Control type="text" sub="address" name="state" value={getValue(element.address, 'state')} onChange={(event) => functions.edit(event.currentTarget)} />
                            <Form.Text className="text-muted">
                                {t('global.labels.state')}
                            </Form.Text>
                        </Col>
                    </Row>
                </Col>
            </Form.Group>
            <PhoneList element={element} functions={functions} t={t} canWrite={canWrite} />
            <Form.Group as={Row} className="border-bottom">
                <Form.Label column sm="2">Email</Form.Label>
                <Col sm="8">
                    <Form.Control type="text" name="email" value={getValue(element, 'email')} onChange={(event) => functions.edit(event.currentTarget)} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="border-bottom">
                <Form.Label column sm="2">Website</Form.Label>
                <Col sm="8">
                    <Form.Control type="text" placeholder="www.mywebsite.it" name="website" value={getValue(element, 'website')} onChange={(event) => functions.edit(event.currentTarget)} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="border-bottom">
                <Form.Label column sm="2">Pec</Form.Label>
                <Col sm="8">
                    <Form.Control type="text" name="pec" value={getValue(element, 'pec')} onChange={(event) => functions.edit(event.currentTarget)} />
                    <Form.Text className="text-muted">
                        Pec
                    </Form.Text>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="border-bottom">
                <Form.Label column sm="2">SDI</Form.Label>
                <Col sm="8">
                    <Form.Control type="text" name="sdi" value={getValue(element, 'sdi')} onChange={(event) => functions.edit(event.currentTarget)} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="border-bottom">
                <Form.Label column sm="2">Note</Form.Label>
                <Col sm="8">
                    <Form.Control type="text" name="cf" value={getValue(element, 'note')} onChange={(event) => functions.edit(event.currentTarget)} />
                </Col>
            </Form.Group>

        </>
    )
}



///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function ElementFormReferent({ element, functions, t, ui_list, canWrite }) {

    const { locale } = useContext(LocaleContext)
    const newreferent = {
        firstname: '',
        lastname: '',
        phone_list: [],
        phone: '',
        mobilephone: '',
        fax: '',
        email: '',
        role: {}
    }
    const [showReferentEdit, setShowReferentEdit] = useState(false)
    const [newReferentEdit, setNewReferentEdit] = useState(true)
    const [curReferent, setCurReferent] = useState(newreferent)
    //----referent
    const handleCloseReferent = () => setShowReferentEdit(false);
    const handleSaveReferent = () => {
        //FIXME
        if (newReferentEdit) {
            let newreferent_list = element.referent_list || []
            newreferent_list.push(curReferent)
            console.log(newreferent_list)
            functions.edit({ name: 'referent_list', value: newreferent_list })
        } else {
            let editlist = element.referent_list
            //            const c = checkComponentPresence(curReferent, element.referent_list)
            editlist.splice(curReferent.index, 1, curReferent)
            functions.edit({ name: 'referent_list', value: editlist })
        }
        setShowReferentEdit(false);
    }
    const handleDeleteReferent = (el, index) => {
        console.log(el)
        let editlist = element.referent_list
        const c = checkComponentPresence(el, element.referent_list)
        editlist.splice(index, 1)
        functions.edit({ name: 'referent_list', value: editlist })
    }
    const handleShowNewReferent = () => {
        setCurReferent(newreferent)
        setNewReferentEdit(true)
        setShowReferentEdit(true)
    }
    const handleShowEditReferent = (i, index) => {
        console.log('edit', i, index)
        i.index = index
        setCurReferent(i)
        setNewReferentEdit(false)
        setShowReferentEdit(true)
    }
    const onChangeHandlerReferent = (event) => {
        console.log(event)
        let { name, value, type } = event
        if (type === 'radio') {
            if (value === 'true') {
                value = true
            } else if (value === 'false') {
                value = false
            }
        }
        console.log(name, value)
        setCurReferent({ ...curReferent, [name]: value })
    }
    const updateRoleList = (b) => {
        functions.updateData('referent_role', b)
    }
    return (
        <>
            <Row>

                {canWrite ?
                    <Col className="text-right"><Button variant="outline-primary" size="sm" onClick={() => handleShowNewReferent()}><FontAwesomeIcon icon="plus-square" />{t('suppliers.labels.referent_add')}</Button></Col>
                    : ''}
            </Row>
            <Row className="border-bottom  border-right mb-1">
                {element.referent_list && element.referent_list.length > 0 ?
                    <Col>
                        <Row >
                            <Col className="bg-secondary text-light ml-1">{t('global.labels.name')} </Col>
                            <Col className="bg-secondary text-light ml-1">{t('global.labels.lastname')} </Col>
                            <Col className="bg-secondary text-light ml-1">{t('global.labels.email')} </Col>
                            <Col className="bg-secondary text-light ml-1">{t('global.labels.phone')} </Col>
                            <Col className="bg-secondary text-light ml-1">{t('suppliers.labels.role')} </Col>
                            {canWrite ?
                                <Col className="bg-secondary text-light ml-1">{t('global.labels.actions')}</Col>
                                : ''}
                        </Row>
                        {element.referent_list.map((i, key) => (
                            <Row key={key} className="border-bottom mb-1 pt-1">
                                <Col className="font-weight-bold">{i.firstname}</Col>
                                <Col className="font-weight-bold">{i.lastname}</Col>
                                <Col>{i.email}</Col>
                                <Col>{renderPhoneList(i.phone_list)}</Col>
                                <Col>
                                    {i.role.name[locale]}
                                </Col>
                                {canWrite ?
                                    <Col className="text-center">
                                        <ButtonGroup>
                                            <Button size="sm" variant="info"><FontAwesomeIcon icon="pencil-alt" onClick={() => handleShowEditReferent(i, key)} /> </Button>
                                            <Button size="sm" variant="danger"><FontAwesomeIcon icon="trash" onClick={() => handleDeleteReferent(i, key)} /> </Button>
                                        </ButtonGroup>
                                    </Col>
                                    : ''}
                            </Row>))
                        }
                    </Col>
                    : <Col> {t('suppliers.messages.no_referent')}</Col>
                }
            </Row>
            <Modal show={showReferentEdit} onHide={handleCloseReferent} size="xl" className="bg-secondary" backdrop="static">
                <Modal.Header closeButton>
                    {newReferentEdit && <Modal.Title>{t('suppliers.labels.new_referent')}</Modal.Title>}
                    {!newReferentEdit && <Modal.Title>{t('suppliers.labels.edit_referent')}</Modal.Title>}
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <Form.Group as={Row} controlId="supplierFirstName" className="border-bottom">
                            <Form.Label column sm="2">{t('global.labels.name')}</Form.Label>
                            <Col sm="8">
                                <Form.Control size="sm" type="text" name="firstname" value={curReferent.firstname} onChange={(event) => onChangeHandlerReferent(event.currentTarget)} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="supplierLastName" className="border-bottom">
                            <Form.Label column sm="2">{t('global.labels.lastname')}</Form.Label>
                            <Col sm="8">
                                <Form.Control size="sm" type="text" name="lastname" value={curReferent.lastname} onChange={(event) => onChangeHandlerReferent(event.currentTarget)} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formSupplierCF" className="border-bottom">
                            <Form.Label column sm="2">{t('supliers.labels.referent.role')} </Form.Label>
                            <Col sm="8">
                                <Form.Control as="select" className="mb-2" defaultValue={curReferent.role.Id} name="role" onChange={(event) => onChangeHandlerReferent({ name: 'role', type: 'select', value: getSelectedElement(event.target.value, ui_list.referent_role_list) })} >
                                    <option value="-1" >{t('equipments.labels.select_role')}</option>
                                    {ui_list.referent_role_list.map((i, key) => (
                                        <option key={key} value={i.Id}  >{i.name[locale]}</option>
                                    ))}
                                </Form.Control>
                            </Col>
                            <Col sm="2">
                                <ModalBaseObject is_new={true} small={true} cursection={{ name: 'referent_role', list: ui_list.referent_role_list }} t={t} functions={{ setCurSection: updateRoleList }} />
                            </Col>
                        </Form.Group>
                        <PhoneList element={curReferent} functions={{ edit: onChangeHandlerReferent }} t={t} canWrite={canWrite} />
                        <Form.Group as={Row} controlId="formSupplierCF" className="border-bottom">
                            <Form.Label column sm="2">Email</Form.Label>
                            <Col sm="8">
                                <Form.Control type="email" className="mb-2" name="email" value={curReferent.email} onChange={(event) => onChangeHandlerReferent(event.currentTarget)} />
                            </Col>
                        </Form.Group>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseReferent}>
                        {t('modal.close')}
                    </Button>
                    {canWrite ?
                        <Button className="btn-myprimary" form="companyForm" onClick={handleSaveReferent}>
                            <FontAwesomeIcon icon="save" />{t('modal.save')}
                        </Button>
                        : ''}
                </Modal.Footer>
            </Modal>

        </>
    )
}




export default translate()(ContractDashboard)
