import React, { useState, useEffect, useLayoutEffect, useContext } from 'react';
import { Route } from "react-router-dom";

import { library } from '@fortawesome/fontawesome-svg-core'
import {
	faBell, faClone,  faShare, faCalendarAlt, faMagic, faClipboard,  faEnvelopeOpen, faBan, faFileExport, faGripVertical, faGripLinesVertical, faGripLines, faForward, faStop, faPlayCircle, faPauseCircle, faUserClock, faSearchMinus, faSearchPlus, faVectorSquare, faCheckDouble, faCommentDollar, faReply, faInfinity, faChevronCircleUp, faChevronCircleDown, faPuzzlePiece, faHistory, faFolder, faTrashAlt, faInfo, faHamburger, faSearch, faPencilAlt, faKey, faArchive, faBook, faCertificate, faIdCardAlt, faPaste, faAngleRight, faAngleLeft, faComment, faCommentAlt, faCircle, faSlash, faCogs, faCog, faArrowLeft, faArrowRight, faArrowCircleRight, faArrowCircleLeft, faFolderOpen, faSquare, faFilter, faBuilding, faCheckSquare, faCalendarCheck, faClipboardCheck, faEye, faEyeSlash, faClock, faRetweet, faQuestionCircle, faInfoCircle, faMinus, faPlus, faMinusSquare, faPlusSquare, faCheck, faTimes, faTimesCircle, faSave, faTrash, faEdit, faUserCog, faUser, faSignOutAlt, faSortAlphaDown, faSortAlphaUp, faSortNumericDown, faSortNumericUp, faSort, faSortUp, faSortDown, faHome, faCalendarWeek, faFileAlt, faWarehouse, faClipboardList, faPrint, faUserFriends, faSoap, faToolbox, faHandshake, faTachometerAlt, faDollyFlatbed, faBroom, faPumpSoap, faTruck, faMapMarkedAlt, faChair, faCalendarDay, faHospitalAlt, faClinicMedical, faListOl, faStethoscope, faProcedures, faBed, faMap, faMapMarked, faScroll, faTools, faMapMarkerAlt, faMapPin, faThumbtack, faFileContract, faExchangeAlt, faFlask, faExclamationTriangle, faExclamationCircle, faTasks, faFileImport, faFileExcel, faFile, faImage, faExternalLinkAlt, faHourglass, faWrench, faHeadSideMask, faStar, faSmile, faFrown, faThumbsDown, faThumbsUp, faMeh, faGrinStars, faAddressCard
} from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import 'react-datetime/css/react-datetime.css'
import 'moment/locale/it'
import { I18n } from "react-polyglot";
import axios from 'axios'
//import EditContext from './components/contexts/edit';
//import { areIntervalsOverlappingWithOptions } from 'date-fns/fp';
//import {AuthProvider} from './components/Firebase/AuthProvider'

import { firebaseAuth } from './provider/AuthProvider'
import { GlobalDataProvider } from './components/contexts/globaldataContext.js';
import DomainContext from './components/contexts/domainContext'
import LocaleContext from './components/contexts/locale.js'

import './App.css';

import Signin from './fComponents/Signin'
import ErrorView from "./pages/ErrorPage"
import AuthApp from "./AuthApp"

import { getValue, myTimestampToDate } from "./util/ui_utils.js"

import { getUserData as getUserData2 } from './Firebase2/Storage2/dbUsers'
import { getDomainData as getDomainData2 } from './Firebase2/Storage2/dbDomain';
import { getSystemGroupById } from './Firebase2/Storage2/dbGroup'


library.add(far,
	faBell, faClone, faShare,  faCalendarAlt, faMagic, faClipboard, faEnvelopeOpen, faBan, faFileExport, faGripVertical, faGripLinesVertical,faGripLines, faForward, faStop, faPlayCircle, faPauseCircle, faUserClock, faSearchMinus, faSearchPlus, faVectorSquare, faCheckDouble, faCommentDollar, faReply, faInfinity, faChevronCircleUp, faChevronCircleDown, faPuzzlePiece, faHistory, faFolder, faTrashAlt, faInfo, faHamburger, faSearch, faPencilAlt, faKey, faArchive, faBook, faCertificate, faIdCardAlt, faPaste, faAngleRight, faAngleLeft, faComment, faCommentAlt, faCircle, faSlash, faCogs, faCog, faArrowLeft, faArrowRight, faArrowCircleRight, faArrowCircleLeft, faFolderOpen, faSquare, faFilter, faBuilding, faCheckSquare, faCalendarCheck, faClipboardCheck, faEye, faEyeSlash, faClock, faRetweet, faQuestionCircle, faInfoCircle, faMinus, faPlus, faMinusSquare, faPlusSquare, faCheck, faTimes, faTimesCircle, faSave, faTrash, faEdit, faUserCog, faUser, faSignOutAlt, faSortAlphaDown, faSortAlphaUp, faSortNumericDown, faSortNumericUp, faSort, faSortUp, faSortDown, faHome, faCalendarWeek, faFileAlt, faWarehouse, faClipboardList, faPrint, faUserFriends, faSoap, faToolbox, faHandshake, faTachometerAlt, faDollyFlatbed, faBroom, faPumpSoap, faTruck, faMapMarkedAlt, faChair, faCalendarDay, faHospitalAlt, faClinicMedical, faListOl, faStethoscope, faProcedures, faBed, faMap, faMapMarked, faScroll, faTools, faMapMarkerAlt, faMapPin, faThumbtack, faFileContract, faExchangeAlt, faFlask, faExclamationTriangle, faExclamationCircle, faTasks, faFileImport, faFileExcel, faFile, faImage, faExternalLinkAlt, faHourglass, faWrench, faHeadSideMask, faStar, faSmile, faFrown, faThumbsDown, faThumbsUp, faMeh, faGrinStars
)


//const hostbase = "claudit.eurikaserver.synology.me"
const hostbase = "claudit.net"
let subdata = { subdomain: '', subodmain_data: {} }


// ==========
const getSubdomain = async () => {
	//DELETED: const hostbaseLength = hostbase.split(".").length
	const host = window.location.host;
	//DELETED: const protocol = window.location.protocol;

	const hostpivot = host.indexOf(hostbase)
	let subdomain = ""
	if (hostpivot > 0) {
		subdomain = host.slice(0, hostpivot - 1)
		let olddomain = window.sessionStorage.getItem('domain')
		let oldcounter = window.sessionStorage.getItem('domain_count') || 0
		console.log("OLDDOMAIN", olddomain, subdomain)
		window.sessionStorage.setItem("domain", subdomain)
		window.sessionStorage.setItem("domain_count", oldcounter + 1)
	}

	if (subdomain.length > 0) {

		const return_data = await getDomainData2(subdomain)
		console.log("domain_datafrom db", return_data)
		sessionStorage.setItem("domain_data", JSON.stringify(return_data))
		let licence_status = true
		if (myTimestampToDate(getValue(return_data, 'endDate')) < new Date()) {
			licence_status = false
		}
		if (!return_data.error) {

			return { subdomain: subdomain, subdomain_data: {...return_data, licence_status: licence_status }}
		} else {
			return { subdomain: subdomain, subodmain_data: {} }
		}
	} else {
		sessionStorage.removeItem("domain_data")
	}

}

const adminID_list = [
	"1ka4qIIEHndWz7UumnLhr82YsAn1", "JryuLB2kcVeXubA3gyuOEM5HZVv1", "a5WFGLFUeGYRTJYF3SI36ZPNMky1", "JMQF68t4N7alZPkaYs80RSfUwWz2"
]

////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//
////////////////////////////////////////////////////////////////////////////////////////////////////////
function App() {
	let lang = 'it'
	if (localStorage.getItem('language')) {
		lang = localStorage.getItem('language')
	}

	const { handleSignout, } = useContext(firebaseAuth)
	const [locale, setLocale] = useState(lang)
	//const {handleSignup} = useContext(firebaseAuth)
	const { token, setToken, userData, setUserData } = useContext(firebaseAuth)
	//console.log("TOKN", token)
	//const token = window.sessionStorage.token
	//console.log(handleSignup)
	//DEBUG: console.log("APP.js   ", token)

	const [subdomain, setSubDomain] = useState({ subdomain: '', subodmain_data: {} })

	async function configUserData(subd) {
		const uid = localStorage.getItem('userId')
		if (uid !== null) {
			console.log("USER DATA APP.js, domain, userId", subd, uid)
			let uData = await getUserData2(subd, uid)
			if (uData.error) {
				console.log("configUserData error:", uData.error)
				localsignout()
			} else {
				let isAdmin = false
				let isGlobalAdmin = false

				console.log("APP.js uid, dbUdata, userId, userdbdata", uid, uData)
				let gdata_dict = {}
				//if (uData && Object.keys(uData).length>0) {

				uData.id = uid
				uData.uid = uid
				if (uData && !uData.disabled) {
					let write_page = []
					let read_page = []
					let contract_dict = {}
					let menu_pages = []
					if (uData.systemgroups && uData.systemgroups.length>0) {
						isAdmin = true
						for (let g = 0; g < uData.systemgroups.length; g++) {
							const gData = gdata_dict[uData.systemgroups[g]] || await getSystemGroupById(uData.systemgroups[g])
							gdata_dict[uData.systemgroups[g]] = gData
							console.log(gData)
							const a = Object.keys(Object.fromEntries(Object.entries(gData.permission_dict).filter(([k, v]) => v === "1")))
							write_page = [...write_page, ...a]
							const b = Object.keys(Object.fromEntries(Object.entries(gData.permission_dict).filter(([k, v]) => v === "0")))
							read_page = [...read_page, ...b]
							menu_pages = [...menu_pages, ...a, ...b]
						}
					}

					if (uData.contractgroups && uData.contractgroups.length>0) {
						console.log("HAS CONTRACT")
						for (let g = 0; g < uData.contractgroups.length; g++) {
							let one_contract_write_page = []
							let one_contract_read_page = []
							for (let c = 0; c < uData.contractgroups[g].permissiongroup.length; c++) {
								const gData = gdata_dict[uData.contractgroups[g].permissiongroup[c]] || await getSystemGroupById(uData.contractgroups[g].permissiongroup[c])
								gdata_dict[uData.contractgroups[g].permissiongroup[c]] = gData
								const a = Object.keys(Object.fromEntries(Object.entries(gData.permission_dict).filter(([k, v]) => v === "1")))
								one_contract_write_page = [...one_contract_write_page, ...a]
								const b = Object.keys(Object.fromEntries(Object.entries(gData.permission_dict).filter(([k, v]) => v === "0")))
								one_contract_read_page = [...one_contract_read_page, ...b]
								menu_pages = [...menu_pages, ...a, ...b]
							}
							if (contract_dict[uData.contractgroups[g].contract_id]) {
								contract_dict[uData.contractgroups[g].contract_id].push({ write_page: one_contract_write_page, read_page: one_contract_read_page, ...uData.contractgroups[g] })
							} else {
								contract_dict[uData.contractgroups[g].contract_id] = [{ write_page: one_contract_write_page, read_page: one_contract_read_page, ...uData.contractgroups[g] }]
							}
							write_page = [...write_page, ...one_contract_write_page]
							read_page = [...read_page, ...one_contract_read_page]
						}
						if (Object.keys(contract_dict).length === 1) {
							//            sessionStorage.setItem('contract', JSON.stringify(el))
							//            sessionStorage.setItem('contractName', )
							//sessionStorage.setItem('contractId', curId)

						}
						uData = { ...uData, read_page, write_page, contract_dict, isAdmin: isAdmin }
					} else {
						if (adminID_list.indexOf(uid) >= 0) {
							isAdmin = true
							isGlobalAdmin = true
						}
						console.log("NO CONTRACTS")
						uData = { ...uData, write_page, read_page, contract_dict, isAdmin: isAdmin, isGlobalAdmin: isGlobalAdmin }
					}
					if (isAdmin || (write_page.length > 0 || read_page.length > 0)) {
						setUserData(uData)
						console.log("uData", uData)
						localStorage.setItem('userData', JSON.stringify(uData))
					} else {
						setToken(null)
					}
				}
			}
		}
	}



	useLayoutEffect(() => {
		setUserData(undefined)
		getSubdomain().then((subd) => {
			subdata = subd
			console.log('----setsubdomain')

			setSubDomain(subd)
			if (subd) {
				configUserData(subd.subdomain)
			}
		})

	}, [token])

	useEffect(() => {
		console.log("useEffect - set sessionStorage.token")
		if (token !== window.localStorage.token) {
			setToken(window.localStorage.token)
		}
	}, [])


	// test....rimosso
	// useLayoutEffect(() => {
	//   configUserData(subdomain.subdomain)
	// }, [window.sessionStorage.userId])




	// In a real app, you should consider preloading default country
	// or load it from the server.
	const [messages, setMessages] = useState({})
	useEffect(() => {
		async function fetchData() {
			const result = await axios.get(`/translations/${locale}.json`)
			// In a real app, you should consider caching the results in an object.
			// To prevent requests for same locale again.
			setMessages(result.data)
		}
		fetchData()
	},
		[locale]
	)

	const localsignout = async () => {
		handleSignout();
	}

	if (!subdata) {
		return (<ErrorView />)
	}

	return (
		<LocaleContext.Provider value={{ locale, setLocale }}>
			<I18n locale={locale} messages={messages}>
				<DomainContext.Provider value={{ domain: subdata.subdomain, domain_name: subdata.subdomain, domain_data: subdata.subdomain_data, loadingdomain: false }}>
					<div className="App">
						<header className="App-header">
						</header>
						{//<BrowserRouter >
						}
						<>
							{/* {console.log("SUBDOMAIN", subdomain, subdomain.subdomain)} */}
							{subdomain.subdomain === '' ?
								<Route exact path="/signin" component={Signin} />
								: <>
									{subdomain && token === null || token === undefined ? 
										<Signin subdomain={subdomain}/> 
										:
										<>
											<GlobalDataProvider>
												<AuthApp userData={userData} appsubdomain={subdomain} />
											</GlobalDataProvider>

										</>
									}
								</>
							}
						</>

						{//</div></BrowserRouter>
						}
					</div>
				</DomainContext.Provider>
			</I18n>
		</LocaleContext.Provider >
	);



}

export default App;
