import React, { useState, useContext, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Badge, Modal, Container, Row, Col, Button, Card, Form, Spinner, ButtonGroup } from 'react-bootstrap';
import QRCode from 'qrcode.react'
import { useReactToPrint } from 'react-to-print';

import EditContext from "../contexts/edit.js"
import DomainContext from '../contexts/domainContext.js';
import LocaleContext from '../contexts/locale.js'
import GlobalDataContext from '../contexts/globaldataContext.js';


import { checkComponentPresence } from "../../util/ui_utils.js"

import AlertSection from "../AlertSection.js"



let load_page = false
const popoverTiming = { show: 250, hide: 100 }
let curdate = { start: new Date(), end: new Date() }


let department_dict = {}

let permission_department_list = []
let permission_structure_list = []


const defaultfilter = { service: [], structure: [], pavillon: [], floor: [], department: [] }


///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const ModalFilter = ({ t, ui_list, filterAppliedList, setFilterAppliedList, element_list }) => {

	const { structureElements } = useContext(GlobalDataContext)
	const [showFilter, setShowFilter] = useState(false)


	const handleCloseFilter = () => {
		setShowFilter(false)
	}

	const resetAppliedFilter = (section) => {
		if (section) {
			setFilterAppliedList({ ...filterAppliedList, [section]: [] })
		} else {
			setFilterAppliedList(defaultfilter)
		}
		setShowFilter(false)
	}

	const updateAppliedFilter = (section, elem) => {
		const c = filterAppliedList[section].indexOf(elem)
		let f_data = [...filterAppliedList[section]]
		if (c === -1) {
			f_data.push(elem)
		} else {
			f_data.splice(c, 1)
		}
		setFilterAppliedList({ ...filterAppliedList, [section]: f_data })
	}



	return (
		<>

			<ButtonGroup>
				<Button size="sm" variant="secondary" onClick={() => setShowFilter(true)}><FontAwesomeIcon icon="filter" /> {t('global.labels.filters')}</Button>
				{filterAppliedList.pavillon.length > 0 ?
					<>
						{Object.keys(structureElements.all_pavillon_dict).map((d, k) => (
							<Button key={k} size="sm" className={filterAppliedList.pavillon.indexOf(d) !== -1 ? '' : 'd-none'}
								variant={filterAppliedList.pavillon.indexOf(d) === -1 ? "outline-secondary" : "secondary"}
								onClick={() => updateAppliedFilter('pavillon', d)}>
								{structureElements.all_pavillon_dict[d].name} <FontAwesomeIcon icon="times" />
							</Button>
						))}
						{filterAppliedList.pavillon.length > 1 ?
							<Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('pavillon')}><FontAwesomeIcon icon="times" /></Button>
							: ''}
					</>
					: ''}
				{filterAppliedList.floor.length > 0 ?
					<>
						{Object.keys(structureElements.all_floor_dict).map((d, k) => (
							<Button key={k} size="sm" className={filterAppliedList.floor.indexOf(d) !== -1 ? '' : 'd-none'}
								variant={filterAppliedList.floor.indexOf(d) === -1 ? "outline-secondary" : "secondary"}
								onClick={() => updateAppliedFilter('floor', d)}>
								<i>{structureElements.all_pavillon_dict[structureElements.all_floor_dict[d].parent_list[1]].name}</i> | {structureElements.all_floor_dict[d].name} <FontAwesomeIcon icon="times" />
							</Button>
						))}
						{filterAppliedList.floor.length > 1 ?
							<Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('floor')}><FontAwesomeIcon icon="times" /></Button>
							: ''}
					</>
					: ''}
			</ButtonGroup>




			<Modal show={showFilter} onHide={handleCloseFilter} centered size="xl">
				<Modal.Header>
					<Modal.Title>

						<FontAwesomeIcon icon="filter" /> {t('global.labels.filters')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container>
						<Row>
							<Col>
								{t('global.labels.pavillon')}
							</Col>
						</Row>
						<Row>
							<Col>
								{console.log(structureElements.all_pavillon_dict)}
								{Object.keys(structureElements.all_pavillon_dict).map((d, k) => (
									<Button size="sm" key={k}
										variant={filterAppliedList.pavillon.indexOf(structureElements.all_pavillon_dict[d].id) === -1 ? "outline-secondary" : "secondary"}
										onClick={(e) => updateAppliedFilter('pavillon', structureElements.all_pavillon_dict[d].id)}
									>
										{structureElements.all_pavillon_dict[d].name}
									</Button>
								))}
								{filterAppliedList.pavillon.length > 0 ?
									<Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('pavillon')}><FontAwesomeIcon icon="times" /></Button>
									: ''}
							</Col>
						</Row>
						<Row>
							<Col>
								{t('global.labels.floor')}
							</Col>
						</Row>
						<Row>
							<Col>
								{console.log(structureElements.all_floor_dict)}
								{Object.keys(structureElements.all_floor_dict).map((d, k) => (
									<Button size="sm" key={k}
										variant={filterAppliedList.floor.indexOf(d) === -1 ? "outline-secondary" : "secondary"}
										onClick={(e) => updateAppliedFilter('floor', d)}
									>
										<i>{structureElements.all_pavillon_dict[structureElements.all_floor_dict[d].parent_list[1]].name}</i> | {structureElements.all_floor_dict[d].name}
									</Button>
								))}
								{filterAppliedList.floor.length > 0 ?
									<Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('floor')}><FontAwesomeIcon icon="times" /></Button>
									: ''}
							</Col>
						</Row>
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseFilter}>
						{t('modal.close')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}


////////////////////////////////////////////////////////////////////////////////////////////////////////
export const ModalDepartmentSelector = ({ item, structure, workarea, canWrite, t, item_list, setItem, year, month, uData }) => {
	const { contract, structureElements } = useContext(GlobalDataContext)

	const edit = useContext(EditContext)

	const componentRef = useRef();
	const footerRef = useRef();
	const childRefAlert = useRef()
	const contractId = contract.id

	const [showPlanEdit, setShowPrePrint] = useState(false)
	const [editedElement, setEditedElement] = useState(false)
	const [event_list, setEventList] = useState([])
	const [full_event_list, setFullEventList] = useState([])
	const [printParams, setPrintParam] = useState([])
	const [filterAppliedList, setFilterAppliedList] = useState(defaultfilter)




	const handleShowPrePrint = async () => {
		edit.resetEditList()
		setShowPrePrint(true)

	}



	const handleClosePrePrint = () => {
		setShowPrePrint(false)
	}


	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
		pageStyle: pageStyle
		/* 		content: () => {
					const tableStat = componentRef.current.cloneNode(true);
					const PrintElem = document.createElement('div');
					PrintElem.appendChild(tableStat);
					return PrintElem;
				},
				pageStyle: pageStyle,
				documentTitle: `qrcode`, */
	});



	const changeSelectedParam = (el_id, param, setParam) => {
		let _param = [...param]
		if (param.indexOf(el_id) >= 0) {
			const c = param.indexOf(el_id)
			_param.splice(c, 1)
		} else {
			_param.push(el_id)
		}
		console.log("UPDATE WA", _param)
		setParam(_param)
	}



	useEffect(() => {
		if (printParams.length === 0 || printParams.length === 2) {
			setEventList(full_event_list)
		} else {
			try {
				if (printParams.indexOf('ordinary') >= 0) {
					setEventList(full_event_list.filter(e => e.extendedProps.type === 'ordinary_intervention'))
				} else if (printParams.indexOf('periodic') >= 0) {
					setEventList(full_event_list.filter(e => e.extendedProps.type === 'periodic_intervention'))
				} else {
					setEventList(full_event_list.filter(e => e.extendedProps.type === 'requested_intervention'))
				}

			} catch (err) {
			}
		}

	}, [printParams])

	useEffect(() => {
		console.log("EDITLIST", edit.editList)

	}, [edit.editList])


	useEffect(() => {
		console.log(filterAppliedList)

	}, [filterAppliedList])



	return (
		<>
			<Button variant={"outline-primary"} onClick={() => handleShowPrePrint()} >
				<FontAwesomeIcon icon="print" />
			</Button>
			<Modal show={showPlanEdit} onHide={handleClosePrePrint} size="xl" backdrop="static" scrollable={true}>
				<Modal.Header closeButton className={editedElement ? "bg-warning" : ""}>
					<AlertSection ref={childRefAlert} />
					<Container fluid>
						<Row className="border-bottom mb-1">
							<Col>
								<Modal.Title>
									{structure.name}: Seleziona reparti per stampa QR code <Badge variant="info">{edit.editList.length} reparti</Badge>
								</Modal.Title>
							</Col>
						</Row>
						<Row>
							<Col sm="1"><FontAwesomeIcon icon="cogs" /></Col>
							<Col>

								<Row>
									<Col className="border-right" sm="3">
										<Row>
											<Col>
												Parametri da stampare
											</Col>
										</Row>
										<Row>
											<Col>
												<ButtonGroup size="sm">
													<Button variant={printParams.indexOf('floor') >= 0 ? 'secondary' : 'outline-secondary'} classfloor="mr-1" onClick={() => changeSelectedParam('floor', printParams, setPrintParam)}>{t('global.labels.floor')}</Button>
												</ButtonGroup>
												<ButtonGroup size="sm">
													<Button variant={printParams.indexOf('name') >= 0 ? 'secondary' : 'outline-secondary'} className="mr-1" onClick={() => changeSelectedParam('name', printParams, setPrintParam)}>{t('global.labels.name')}</Button>
												</ButtonGroup>
											</Col>
										</Row>
									</Col>
									<Col className="text-right">
										<ModalFilter t={t} filterAppliedList={filterAppliedList} setFilterAppliedList={setFilterAppliedList} element_list={structureElements.department_list.filter(d => d.parent_list[0] === structure.id)} />
									</Col>
								</Row>
							</Col>
						</Row>
					</Container>
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<Row>
							<Col className="text-light bg-secondary mr-1">
								Selezione
							</Col>
							<Col className="text-light bg-secondary mr-1">
								Ala/Palazzina
							</Col>
							<Col className="text-light bg-secondary mr-1">
								Piano
							</Col>
							<Col className="text-light bg-secondary mr-1">
								Reparto
							</Col>
						</Row>
						{structureElements.department_list.filter(
							d => d.parent_list[0] === structure.id
								&& (filterAppliedList.pavillon.length === 0 || filterAppliedList.pavillon.indexOf(d.parent_list[1]) >= 0)
								&& (filterAppliedList.floor.length === 0 || filterAppliedList.floor.indexOf(d.parent_list[2]) >= 0)
						).map((s, sk) => (
							<Row key={sk} className="one_element border-bottom">
								<Col>
									{checkComponentPresence(s, edit.editList, 'id') === -1 ?
										<Button variant="outline-info" className="mr-1" size="sm" onClick={() => edit.toggleEditList(s, s.parent_list[0], structure)}><FontAwesomeIcon icon="check" /></Button>
										:
										<Button variant="info" className="mr-1" size="sm" onClick={() => edit.toggleEditList(s, s.parent_list[0], structure)}><FontAwesomeIcon icon="check" /></Button>
									}
								</Col>
								<Col>{structureElements.all_pavillon_dict[s.parent_list[1]]? structureElements.all_pavillon_dict[s.parent_list[1]].name: '----'}</Col>
								<Col>{structureElements.all_floor_dict[s.parent_list[2]]? structureElements.all_floor_dict[s.parent_list[2]].name: '---'}</Col>
								<Col>
									{s.name}
								</Col>
							</Row>
						))}
					</Container>
					<div className="d-none">

						<div ref={componentRef} className="print_page">
							<style type="text/css" media="print">{"\
  @page {\ size: A4;\ }\
"}</style>
								{edit.editList.map((element, kr) => (
									kr % 50 === 0 && kr !== 0 ?
										<div className="page-break" />
										:
										<div className="qrlabel single_label" key={kr}>
											<div className="qr-title text-center">
												{printParams.indexOf('floor') >= 0 ?
													<>{structureElements.all_floor_dict[element.parent_list[2]].name}&nbsp;|&nbsp;</>
													: <>&nbsp;</>}
												{printParams.indexOf('name') >= 0 ?
													element.name
													: <>&nbsp;</>}
											</div>
											<div className="text-center">
												<QRCode size={128} value={JSON.stringify({ id: element.id, nome: element.name, dimensione: element.size, parent_list: element.parent_list })} />
											</div>
										</div>

								))}
						</div>
					</div>

				</Modal.Body>
				<Modal.Footer>
					<Container fluid>
						<Row className="h4">
							<Col className="text-right">
								<Button variant="secondary" onClick={handleClosePrePrint} className="mr-1">
									{t('modal.close')}
								</Button>
								<Button variant="info" disabled={edit.editList.length === 0} onClick={handlePrint} className="ml-1">
									<FontAwesomeIcon icon="print" />  {t('modal.print')}
								</Button>
							</Col>
						</Row>
					</Container>
				</Modal.Footer>
			</Modal>
		</>
	)
}




const pageStyle = `

@media all {
	.page-break {
	  display: none;
	}
  }
	body{
		min-width: fit-content;
	}
  
  @media print {
	html {
		size: 210mm 297mm;
		width: 210mm !important;
		margin: 0;
	  overflow: initial !important;
	  -webkit-print-color-adjust: exact;
	  color-adjust: exact;
	}
	body {
		size: 100mm 100mm;
		width: 100mm !important;
		height: 100mm;
		min-width: 100mm;
		max-width: 100mm;

		margin: 0;
	  overflow: initial !important;
	  -webkit-print-color-adjust: exact;
	  color-adjust: exact;
	}
	.print_page {
		width: 210mm !important;
		height: 296mm !important; 
		margin: 0;
		transform-origin: top left;
		transform: scale(1.6);
	  overflow: initial !important;
	  -webkit-print-color-adjust: exact;
	  color-adjust: exact;
	}
	.preprint_page {
		width: 210mm !important;
		height: 296mm !important; 
		margin: 0;
	  overflow: initial !important;
	  -webkit-print-color-adjust: exact;
	  color-adjust: exact;
	}
  }
  @media print {
    html, body {
    height: auto; }
} 
  @media print {
	.page-break {
	  margin-top: 1rem;
	  display: block;
	  page-break-before: always;
	}
  }
  @media print {
	.print_data {
		font-size:0.8em;
	}
  }
  
  @page {
	size: A4 portait;
	margin: 10mm;
  } 
`;



const pageStyleA = `


@media all {
	.page-break {
	  display: none;
	}
  }
  @media print {
	html {
	  width:100%;
	  height: 100%;
	  overflow: initial !important;
	  margin:0;
	  padding:0;
	  border: 3px solid red;
	  background-color: #ffaa00;
	  -webkit-print-color-adjust: exact;
	  color-adjust: exact;
	}
	body {
	  width:130%;
	  max-width:130%;
	  height:100%;
	  margin:0;
	  padding:0;
	  overflow: initial !important;
	  border: 10px solid green;
	  -webkit-print-color-adjust: exact;
	  color-adjust: exact;
	}
	.preprint_page {
	  max-width:120% !important;
	  height:120% !important;
	  margin:0;
	  padding:0;
	  overflow: initial !important;
	  border: 1px solid black;
	  -webkit-print-color-adjust: exact;
	  color-adjust: exact;
	}
	.page-break {
	  margin-top: 1rem;
	  display: block;
	  page-break-before: always;
	}
	div.page-footer {
		display:block;
		position: fixed;
		z-index: 9;
		bottom: 0;
		width: 100%;
		height: 50px;
		font-size: 9px;
		color: #000;
		page-break-after: always;
	  }
	  .single_label {
		width: 50mm !important;
		border: 1px dotted orange;
		height: 50mm !important;
	  }
	.print_data {
		font-size:0.8em;
	}
  }
  

`;


